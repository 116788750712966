import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { VideoInitialStateType, VideoDetail } from "../../../models/events/event-video.model";
import VideoService from "../../../services/events/event-video.service";

const initialState: VideoInitialStateType = {
  data: {} as VideoDetail,
  loading: true,
  error: undefined,
};

export const retrieveVideo = createAsyncThunk("video/retrieve", async (eventCode: string) => {
  const service = new VideoService();

  const response = await service.getVideoPage(eventCode);
  return response.data;
});

const videoSlice = createSlice({
  name: "video",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveVideo.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveVideo.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as VideoDetail;
        }
      })
      .addCase(retrieveVideo.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default videoSlice.reducer;
