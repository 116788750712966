import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import ConferenceRoutes from "../routes/eucrm-routes";
import LoadingComp from "../components/re-usable/loading/loading";
import Footer from "../components/footer/footer";

const EUCRMConferenceLayout = () => {

  return (
    <>
        <Suspense fallback={<LoadingComp />}>
          <Routes>
            {ConferenceRoutes.map((route, index) => {
              return route.component ? <Route key={index} path={route.path} element={route.component} /> : null;
            })}
          </Routes>
        </Suspense>
        <Footer />
    </>
  );
};

export default EUCRMConferenceLayout;
