import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import { WhoWeAreNewsInitialStateType, WhoWeAreNewsTypes } from '../../../models/who-we-are/news-model'
import NewsService from '../../../services/who-we-are/news.services';

const initialState: WhoWeAreNewsInitialStateType ={
    WhoWeAreNewsData: [],
    WhoWeAreNewsLoading: true,
    WhoWeAreNewsError: undefined
}

export const retrieveWhoToAreNews = createAsyncThunk('searchWhoToAreNews/retrieve',
    async(data, { rejectWithValue }) => {
        try {
            const response = await NewsService.getAllNews()
            return [response.data]
        
        } catch (error: any) {
            if (!error.response) {
                throw error
              }
            return rejectWithValue(error.response.status)
            
        }
    }
)

const WhoToAreNewsSlice = createSlice({
    name: 'WhoToAre-News',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveWhoToAreNews.pending, (state) => {
                state.WhoWeAreNewsLoading = true;
            })
            .addCase(retrieveWhoToAreNews.fulfilled, (state, action) => {
                if(action.payload){
                    state.WhoWeAreNewsLoading = false;
                    state.WhoWeAreNewsData = action.payload as WhoWeAreNewsTypes[];
                }
            })
            .addCase(retrieveWhoToAreNews.rejected, (state, {payload}) =>{
                state.WhoWeAreNewsLoading = false;
                state.WhoWeAreNewsError = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: {entities: any;}) => state.entities;
export default WhoToAreNewsSlice.reducer