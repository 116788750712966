import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { MyProgramsInitialStateType, MyProgramsResponse } from "../../../models/events/event-myprograms.model";
import MyProgramsService from "../../../services/events/event-myprograms.service";

const initialState: MyProgramsInitialStateType = {
  data: {} as MyProgramsResponse,
  loading: true,
  error: undefined,
};

export const retrieveMyPrograms = createAsyncThunk("myPrograms/retrieve", async (userId: string) => {
  const service = new MyProgramsService();

  const response = await service.getMyPrograms(userId);
  return response.data;
});

const myProgramsSlice = createSlice({
  name: "myPrograms",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveMyPrograms.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveMyPrograms.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as MyProgramsResponse;
        }
      })
      .addCase(retrieveMyPrograms.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default myProgramsSlice.reducer;
