import http from "../http-common";

export default class {
  getRacMenu() {
    let url = `/rac-menu`;

    return http.get(url);
  }
}

