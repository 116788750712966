import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import { ProductDetailsInitialStateType, ProductDetailsType } from '../../../models/products/product-details-model';
import ProductDetailsService from '../../../services/products/product-details.service';

const initialState: ProductDetailsInitialStateType = {
    ProductDetailsData: [],
    ProductDetailsLoading: true,
    ProductDetailsError: undefined
};


export const retrieveProductDetails = createAsyncThunk('ProductDetails/retrieve',
    async (path: string, { rejectWithValue }) => {
        try {

            const response = await ProductDetailsService.getProductDetails(path)
            return [response.data]

        } catch (error: any) {
            if (!error.response) {
                throw error
            }
            return rejectWithValue(error.response.status)
        }
    }
)

const ProductDetailsSlice = createSlice({ 
    name: 'ProductDetails',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveProductDetails.pending, (state) => {
                state.ProductDetailsLoading = true;
            })
            .addCase(retrieveProductDetails.fulfilled, (state, action) => {
                if (action.payload) {
                    state.ProductDetailsLoading = false;
                    state.ProductDetailsData = action.payload as ProductDetailsType[];
                }
            })
            .addCase(retrieveProductDetails.rejected, (state, { payload }) => {
                state.ProductDetailsLoading = false;
                state.ProductDetailsError = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default ProductDetailsSlice.reducer;
