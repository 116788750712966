import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { NewsArticlesSearchCategoryInitialStateType, NewsArticlesSearchCategoryTypes } from "../../../models/news-articles/news-articles-search-category-model";
import NewsArticlesSearchCategoryServices from "../../../services/news-articles/news-articles-search-category.service";

const initialState: NewsArticlesSearchCategoryInitialStateType = {
    NewsArticlesSearchCategoryData: [],
    NewsArticlesSearchCategoryLoading: true,
    NewsArticlesSearchCategoryError: undefined,
};



export const retrieveNewsArticlesSearchCategory = createAsyncThunk(
  "NewsArticlesSearchCategory/retrieve",
  async (__data,  { rejectWithValue }) => {
    try {
        const response = await NewsArticlesSearchCategoryServices.getAllNewsArticlesCategory();
        return [response.data];
    }catch (error: any) {
        if (!error.response) {
            throw error
          }
        return rejectWithValue(error.response.status) 
    }   
})
  

const newsArticlesSearchCategorySlice = createSlice({
  name: "NewsArticlesSearc",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveNewsArticlesSearchCategory.pending, (state) => {
        state.NewsArticlesSearchCategoryLoading = true;
      })
      .addCase(retrieveNewsArticlesSearchCategory.fulfilled, (state, action) => {
        if (action.payload) {
          state.NewsArticlesSearchCategoryLoading = false;
          state.NewsArticlesSearchCategoryData = action.payload as NewsArticlesSearchCategoryTypes[];
        }
      })
      .addCase(retrieveNewsArticlesSearchCategory.rejected, (state, { payload }) => {
        state.NewsArticlesSearchCategoryLoading = false;
        state.NewsArticlesSearchCategoryError = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default newsArticlesSearchCategorySlice.reducer;
