import { apiErrorHandler } from './../../errors/api-error-handler';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BookSaleService from "../../services/book-sale.service"
import { BookSaleInitialStateType, BookSaleTypes } from '../../models/book-sale-model';

const initialState: BookSaleInitialStateType = {
    BookSaleData: [],
    BookSaleLoading: true,  
    BookSaleError: undefined,
} ;

export const retrieveBookSale = createAsyncThunk('BookSale/retrieve',
    async (data, { rejectWithValue }) => {
        try {
            const response = await BookSaleService.getAllBookSale();
            return [response.data]
        
        } catch (error: any) {
            if (!error.response) {
                throw error
              }
            return rejectWithValue(error.response.status)
            
        }
        
    }
)


const BookSaleSlice = createSlice({
    name: 'Book-sale',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(retrieveBookSale.pending, (state) => {
                    state.BookSaleLoading = true;
            })
            .addCase(retrieveBookSale.fulfilled, (state, action) => {
                if(action.payload){
                    state.BookSaleLoading = false;
                    state.BookSaleData = action.payload as BookSaleTypes[];
                }   
            })        
            .addCase(retrieveBookSale.rejected, (state, {payload}) => {
                    state.BookSaleLoading = false;
                    state.BookSaleError = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default BookSaleSlice.reducer;
