import { PrimaryMenuInitialStateType, PrimaryMenu } from './../../models/primary-menu-model';
import { apiErrorHandler } from './../../errors/api-error-handler';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PrimaryMenuService from "../../services/primary-menu.service"

const initialState: PrimaryMenuInitialStateType = {
    data: [],
    loading: true,  
    error: undefined,
} ;

export const retrievePrimaryMenu = createAsyncThunk('primaryMenu/retrieve',
    async (data, { rejectWithValue }) => {
        try {
            const response = await PrimaryMenuService.getAllprimaryMenu();
         return [response.data]
         
        } catch (error: any) {
            if (!error.response) {
                throw error
              }
            return rejectWithValue(error.response.status)
            
        }
        
    }
)



const primaryMenuSlice = createSlice({
    name: 'primaryMenu',
    initialState: initialState,
    reducers: {


    },
    extraReducers: (builder) => {
        builder
            .addCase(retrievePrimaryMenu.pending, (state) => {
                    state.loading = true;
            })
            .addCase(retrievePrimaryMenu.fulfilled, (state, action) => {
                if(action.payload){
                    state.loading = false;
                    state.data = action.payload as PrimaryMenu[];
                }   
            })        
            .addCase(retrievePrimaryMenu.rejected, (state, {payload}) => {
                    state.loading = false;
                    state.error = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default primaryMenuSlice.reducer;
