import { apiErrorHandler } from './../../errors/api-error-handler';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ConvergenceCarouselService from "../../services/convergence-carousel.service"
import { ConvergenceCarouselInitialStateType, ConvergenceCarousel } from '../../models/convergence-carousel-model';

const initialState: ConvergenceCarouselInitialStateType = {
    data: [],
    loading: true,  
    error: undefined,
} ;

export const retrieveConvergenceCarousel = createAsyncThunk('convergenceCarousel/retrieve',
    async (data, { rejectWithValue }) => {
        try {
            const response = await ConvergenceCarouselService.getAllConvergenceCarousel();
         return [response.data]
         
        } catch (error: any) {
            if (!error.response) {
                throw error
              }
            return rejectWithValue(error.response.status)
            
        }
        
    }
)



const convergenceCarouselSlice = createSlice({
    name: 'primaryMenu',
    initialState: initialState,
    reducers: {


    },
    extraReducers: (builder) => {
        builder
            .addCase(retrieveConvergenceCarousel.pending, (state) => {
                    state.loading = true;
            })
            .addCase(retrieveConvergenceCarousel.fulfilled, (state, action) => {
                if(action.payload){
                    state.loading = false;
                    state.data = action.payload as ConvergenceCarousel[];
                }   
            })        
            .addCase(retrieveConvergenceCarousel.rejected, (state, {payload}) => {
                    state.loading = false;
                    state.error = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default convergenceCarouselSlice.reducer;
