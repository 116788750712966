import {
    ConvergenceCarouselSlidesInitialStateType,
    ConvergenceCarouselSlideType,
} from "../../models/convergence-carousel-slides-model";
import ConvergenceCarouselSlidesService from "../../services/convergence-carousel-slides.service";
import { apiErrorHandler } from "../../errors/api-error-handler";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState: ConvergenceCarouselSlidesInitialStateType = {
    data: [],
    loading: true,
    error: undefined,
};

export const retrieveConvergenceCarouselSlides = createAsyncThunk(
    "convergenceCarouselSlides/retrieve",
    async () => {
        const response =
            await ConvergenceCarouselSlidesService.getConvergenceCarouselSlides();
        return [...response.data.result];
    }
);

const convergenceCarouselSlidesSlice = createSlice({
    name: "convergenceCarouselSlides",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveConvergenceCarouselSlides.pending, (state) => {
                state.loading = true;
            })
            .addCase(
                retrieveConvergenceCarouselSlides.fulfilled,
                (state, action) => {
                    if (action.payload) {
                        state.loading = false;
                        state.data =
                            action.payload as ConvergenceCarouselSlideType[];
                    }
                }
            )
            .addCase(
                retrieveConvergenceCarouselSlides.rejected,
                (state, { payload }) => {
                    state.loading = false;
                    state.error = apiErrorHandler(Number(payload));
                }
            );
    },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default convergenceCarouselSlidesSlice.reducer;
