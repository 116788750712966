import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { ArticlesFilteredsInitialStateType, ArticlesFilteredsTypes } from "../../../models/news-articles/articles-filtered-model";
import ArticlesFilteredsServices from "../../../services/news-articles/articles-filtered.service";

const initialState: ArticlesFilteredsInitialStateType = {
    ArticlesFilteredsData: [],
    ArticlesFilteredsPinnedData: [],
    ArticlesFilteredsData393: [],
    ArticlesFilteredsData397: [],
    ArticlesFilteredsData398: [],
    ArticlesFilteredsData400: [],
    ArticlesFilteredsData404: [],
    ArticlesFilteredsLoading: true,
    ArticlesFilteredsError: undefined,
};

type QueryParamsType = {
  isInclude: boolean, 
  noOfArticles: number
} 

export const retrieveArticlesFiltereds398 = createAsyncThunk(
  "ArticlesFiltereds398/retrieve",
  async (queryParams: QueryParamsType,  { rejectWithValue }) => {
    try {
        const response = await ArticlesFilteredsServices.getAllArticlesFiltereds('398', queryParams.isInclude, queryParams.noOfArticles);
        return [response.data];
    }catch (error: any) {
        if (!error.response) {
            throw error
          }
        return rejectWithValue(error.response.status) 
    }   
})
  

const ArticlesFiltereds398Slice = createSlice({
  name: "NewsArticlesSearch398",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveArticlesFiltereds398.pending, (state) => {
        state.ArticlesFilteredsLoading = true;
      })
      .addCase(retrieveArticlesFiltereds398.fulfilled, (state, action) => {
        if (action.payload) {
          state.ArticlesFilteredsLoading = false;
          state.ArticlesFilteredsData398 = action.payload as ArticlesFilteredsTypes[];
        }
      })
      .addCase(retrieveArticlesFiltereds398.rejected, (state, { payload }) => {
        state.ArticlesFilteredsLoading = false;
        state.ArticlesFilteredsError = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default ArticlesFiltereds398Slice.reducer;
