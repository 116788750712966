import http from "../../../http-common"
import { pageSizeType, sortByType } from "../../../redux/slice/news-articles/news-articles-search-slice";

class RegulatoryFocusFeatureFocusServices {
    getAllRegulatoryFocusFeatureFocus() {
        return http.get('/regulatory-focus/feature-focus')
    }
}


export default new RegulatoryFocusFeatureFocusServices();