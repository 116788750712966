import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import { MyContentInitialStateType, MyContentResponse, MyContentTaxonomyInitialStateType, MyContentTaxonomyResponse } from "../../models/my-content.model";
import MyContentService from "../../services/my-content.service";

/* /api/my-content/news */
export const retrieveMyContentNews = createAsyncThunk("myContentNews/retrieve", async (categoryIDs: string) => {
  const service = new MyContentService();
  const response = await service.getNews(categoryIDs);
  return response.data;
});

const myContentNewsSlice = createSlice({
  name: "myContentNews",
  initialState: {
    data: {} as MyContentResponse,
    loading: false,
    error: undefined,
  } as MyContentInitialStateType,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveMyContentNews.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveMyContentNews.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as MyContentResponse;
        }
      })
      .addCase(retrieveMyContentNews.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});


/* /api/my-content/books */
export const retrieveMyContentBooks = createAsyncThunk("myContentBooks/retrieve", async (categoryIDs: string) => {
  const service = new MyContentService();
  const response = await service.getBooks(categoryIDs);
  return response.data;
});

const myContentBooksSlice = createSlice({
  name: "myContentBooks",
  initialState: {
    data: {} as MyContentResponse,
    loading: false,
    error: undefined,
  } as MyContentInitialStateType,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveMyContentBooks.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveMyContentBooks.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as MyContentResponse;
        }
      })
      .addCase(retrieveMyContentBooks.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});


/* /api/my-content/events */
export const retrieveMyContentEvents = createAsyncThunk("myContentEvents/retrieve", async (categoryIDs: string) => {
  const service = new MyContentService();
  const response = await service.getEvents(categoryIDs);
  return response.data;
});

const myContentEventsSlice = createSlice({
  name: "myContent",
  initialState: {
    data: {} as MyContentResponse,
    loading: false,
    error: undefined,
  } as MyContentInitialStateType,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveMyContentEvents.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveMyContentEvents.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as MyContentResponse;
        }
      })
      .addCase(retrieveMyContentEvents.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});


/* /api/my-content/learning */
export const retrieveMyContentLearning = createAsyncThunk("myContentLearning/retrieve", async (categoryIDs: string) => {
  const service = new MyContentService();
  const response = await service.getLearning(categoryIDs);
  return response.data;
});

const myContentLearningSlice = createSlice({
  name: "myContent",
  initialState: {
    data: {} as MyContentResponse,
    loading: false,
    error: undefined,
  } as MyContentInitialStateType,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveMyContentLearning.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveMyContentLearning.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as MyContentResponse;
        }
      })
      .addCase(retrieveMyContentLearning.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});


/* /api/my-content/taxonomies */
export const retrieveMyContentTaxonomies = createAsyncThunk("myContentTaxonomies/retrieve", async () => {
  const service = new MyContentService();

  const response = await service.getTaxonomies();
  return response.data;
});

const myContentTaxonomiesSlice = createSlice({
  name: "myContent",
  initialState: {
    data: {} as MyContentTaxonomyResponse,
    loading: false,
    error: undefined,
  } as MyContentTaxonomyInitialStateType,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveMyContentTaxonomies.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveMyContentTaxonomies.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as MyContentTaxonomyResponse;
        }
      })
      .addCase(retrieveMyContentTaxonomies.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});


export const selectAllEntities = (state: { entities: any }) => state.entities;
export default {
  news: myContentNewsSlice.reducer,
  books: myContentBooksSlice.reducer,
  events: myContentEventsSlice.reducer,
  learning: myContentLearningSlice.reducer,
  taxonomies: myContentTaxonomiesSlice.reducer,
};
