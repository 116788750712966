import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { ArticlesFiltereds393InitialStateType, ArticlesFiltereds393Types } from "../../../models/news-articles/articles-filtered393-model";
import ArticlesFilteredsServices from "../../../services/news-articles/articles-filtered.service";

const initialState: ArticlesFiltereds393InitialStateType = {
    ArticlesFiltereds393Data: [],
    ArticlesFilteredsLoading: true,
    ArticlesFilteredsError: undefined,
};

type QueryParamsType = {
  isInclude: boolean, 
  noOfArticles: number
} 

export const retrieveArticlesFiltereds393 = createAsyncThunk(
  "ArticlesFiltereds393/retrieve",
  async (queryParams: QueryParamsType,  { rejectWithValue }) => {
    try {
        const response = await ArticlesFilteredsServices.getAllArticlesFiltereds('393', queryParams.isInclude, queryParams.noOfArticles);
        return [response.data];
    }catch (error: any) {
        if (!error.response) {
            throw error
          }
        return rejectWithValue(error.response.status) 
    }   
})
  

const ArticlesFiltereds393Slice = createSlice({
  name: "NewsArticlesSearch393",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveArticlesFiltereds393.pending, (state) => {
        state.ArticlesFilteredsLoading = true;
      })
      .addCase(retrieveArticlesFiltereds393.fulfilled, (state, action) => {
        if (action.payload) {
          state.ArticlesFilteredsLoading = false;
          state.ArticlesFiltereds393Data = action.payload as ArticlesFiltereds393Types[];
        }
      })
      .addCase(retrieveArticlesFiltereds393.rejected, (state, { payload }) => {
        state.ArticlesFilteredsLoading = false;
        state.ArticlesFilteredsError = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default ArticlesFiltereds393Slice.reducer;
