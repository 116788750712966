import http from "../http-common";

class PageMenuServices {
  getPageMenu(pathId: string) {
    const queryParams = new URLSearchParams(window.location.search);
    const isPreview = queryParams.get("isPreview") && queryParams.get("wg");
    let addQuery = isPreview ? `&isPreview=true&wg=${queryParams.get("wg")}` : "";

    return http.get(`/page-menu?path=${pathId}${addQuery}`);
  }
}

export default new PageMenuServices();
