import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import { CurrentJobOpeningsInitialStateType, CurrentJobOpeningsTypes } from '../../../models/who-we-are/current-job-openings-model'
import CurrentJobsOpeningsService from '../../../services/who-we-are/current-job-openings.service';

const initialState: CurrentJobOpeningsInitialStateType ={
    CurrentJobOpeningsData: [],
    CurrentJobOpeningsLoading: true,
    CurrentJobOpeningsError: undefined
}

export const retrieveCurrentJobOpenings = createAsyncThunk('searchCurrentJobOpenings/retrieve',
    async(path: string, { rejectWithValue }) => {
        try {
            const response = await CurrentJobsOpeningsService.getAllCurrentJobsOpenings(path)
            return [response.data]
        
        } catch (error: any) {
            if (!error.response) {
                throw error
              }
            return rejectWithValue(error.response.status)
            
        }
    }
)

const CurrentJobOpeningsSlice = createSlice({
    name: 'WhoToAre-News',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveCurrentJobOpenings.pending, (state) => {
                state.CurrentJobOpeningsLoading = true;
            })
            .addCase(retrieveCurrentJobOpenings.fulfilled, (state, action) => {
                if(action.payload){
                    state.CurrentJobOpeningsLoading = false;
                    state.CurrentJobOpeningsData = action.payload as CurrentJobOpeningsTypes[];
                }
            })
            .addCase(retrieveCurrentJobOpenings.rejected, (state, {payload}) =>{
                state.CurrentJobOpeningsLoading = false;
                state.CurrentJobOpeningsError = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: {entities: any;}) => state.entities;
export default CurrentJobOpeningsSlice.reducer