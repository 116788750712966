import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import {
  LatestEventsInitialStateType,
  LatestEventsType,
} from "../../models/latest-events-model";
import LatestEventsService from "../../services/latest-events.service";

const initialState: LatestEventsInitialStateType = {
  popularEventsData: [],
  loading: true,
  error: undefined,
};

export const retrieveLatestEvents = createAsyncThunk(
  "latestEvents/retrieve",
  async (numberOfEvents: number) => {
    const response = await LatestEventsService.getLatestEvents(numberOfEvents);
    return [...response.data.result];
  }
);

const latestEventsSlice = createSlice({
  name: "latestEvents",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveLatestEvents.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveLatestEvents.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.popularEventsData = action.payload as LatestEventsType[];
        }
      })
      .addCase(retrieveLatestEvents.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default latestEventsSlice.reducer;
