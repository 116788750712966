import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import { SearchPageRacExamsResponse, SearchRacExamsInitialStateType } from '../../../models/search-page/search-rac-exams-model';
import SearchRacExamService from '../../../services/search-page/search-rac-exams-service';

const initialState: SearchRacExamsInitialStateType = {
    searchResultsRacExamData: [],
    loading: true,  
    error: undefined
} ;

export const retrieveSearchRacExam = createAsyncThunk('searchRacExam/retrieve',
    async (pageNumber: number, { rejectWithValue }) => {
        try {
            const response = await SearchRacExamService.getRacExamSearchResults(pageNumber)
            return [response.data]
         
        } catch (error: any) {
            if (!error.response) {
                throw error
              }
            return rejectWithValue(error.response.status) 
        }   
    }
)

const searchRacExamSlice = createSlice({
    name: 'searchRacExam',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveSearchRacExam.pending, (state) => {
                    state.loading = true;
            })
            .addCase(retrieveSearchRacExam.fulfilled, (state, action) => {
                if(action.payload){
                    state.loading = false;
                    state.searchResultsRacExamData = action.payload as SearchPageRacExamsResponse[];
                }   
            })        
            .addCase(retrieveSearchRacExam.rejected, (state, {payload}) => {
                    state.loading = false;
                    state.error = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default searchRacExamSlice.reducer;
