export const localStorageHelper = {
  get(key: string) {
    try {
      const value = localStorage.getItem(key);
      if (value) {
        return JSON.parse(value);
      }
      return null;
    } catch (err) {
      return null;
    }
  },
  store(key: string, value: string) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (err) {}
  },
};
