import React, { ReactNode } from "react";
import { Navigate } from 'react-router-dom';

const SitePages = React.lazy(() => import('../pages/grs/grs-index'));
/*const GRSHome = React.lazy(() => import('../pages/grs/grs-home'));
const GRSHotel = React.lazy(() => import('../pages/grs/grs-hotel-travel'));
const GRSRegister = React.lazy(() => import('../pages/grs/grs-register'));
const GRSSchedule = React.lazy(() => import('../pages/grs/grs-schedule'));
const GRSJustificationLetter = React.lazy(() => import('../pages/grs/grs-justification-letter'));
const GRSExhibitorsSponsorships = React.lazy(() => import('../pages/grs/grs-sponsorship'));*/
const PageNotFound = React.lazy(() => import('../pages/errors/page-not-found'));

export type route = {
    path: string;
    component: ReactNode;
    exact: boolean | undefined;
    name?: string;
};

const GRSCConference: route[] = [
  { path: '/', exact: true, name: 'GRS Home', component: <Navigate to={`/grs/home${window.location.search}`} replace /> },
  { path: '/*', exact: true, name: 'GRS All', component: <SitePages /> },

  /*{ path: "/exhibitors-sponsorships", exact: true, name: "GRS", component: <GRSExhibitorsSponsorships /> },
  { path: "/hotel", component: <GRSHotel /> },
  { path: "/register", component: <GRSRegister /> },
  { path: "/schedule", component: <GRSSchedule /> },
  { path: "/justification-letter", component: <GRSJustificationLetter /> },
  //alternate urls
  { path: "/hotel-travel", exact: true, name: "GRS", component: <GRSHotel /> },
  { path: "/schedule-at-a-glance", exact: true, component: <GRSSchedule /> },*/
  //end of alternate urls
  { path: "/*", exact: true, component: <PageNotFound /> },
];

export default GRSCConference;

