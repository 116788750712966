import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import { BordOfEditorsInitialStateType, BordOfEditorsTypes } from "../../models/bord-of-editors-model";
import EditorialAdvisoryCommitteeServices from "../../services/editorial-advisory-committee.service";

const initialState: BordOfEditorsInitialStateType = {
  BordOfEditorsData: [],
  BordOfEditorsLoading: true,
  BordOfEditorsError: undefined,
};

export const retrieveBoardOfEditors = createAsyncThunk(
  "BoardOfEditors/retrieve",
  async () => {
    const response = await EditorialAdvisoryCommitteeServices.getAllEditorialAdvisoryCommittee('Board-of-Editors');
    
    return [response.data];
  }
);

const boardOfEditorsSlice = createSlice({
  name: "board-of-Editors",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveBoardOfEditors.pending, (state) => {
        state.BordOfEditorsLoading = true;
      })
      .addCase(retrieveBoardOfEditors.fulfilled, (state, action) => {
        if (action.payload) {
          state.BordOfEditorsLoading = false;
          state.BordOfEditorsData = action.payload as BordOfEditorsTypes[];
        }
      })
      .addCase(retrieveBoardOfEditors.rejected, (state, { payload }) => {
        state.BordOfEditorsLoading = false;
        state.BordOfEditorsError = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default boardOfEditorsSlice.reducer;
