import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { NewsArticlesSearchAuthorInitialStateType, NewsArticlesSearchAuthorTypes } from "../../../models/news-articles/news-articles-search-author-model";
import NewsArticlesSearchAuthorServices from "../../../services/news-articles/news-articles-search-author.service";

const initialState: NewsArticlesSearchAuthorInitialStateType = {
    NewsArticlesSearchAuthorData: [],
    NewsArticlesSearchAuthorLoading: true,
    NewsArticlesSearchAuthorError: undefined,
};



export const retrieveNewsArticlesSearchAuthor = createAsyncThunk(
  "NewsArticlesSearchAuthor/retrieve",
  async (__data,  { rejectWithValue }) => {
    try {
        const response = await NewsArticlesSearchAuthorServices.getAllNewsArticlesAuthors();
        return [response.data];
    }catch (error: any) {
        if (!error.response) {
            throw error
          }
        return rejectWithValue(error.response.status) 
    }   
})
  

const newsArticlesSearchAuthorSlice = createSlice({
  name: "NewsArticlesSearc",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveNewsArticlesSearchAuthor.pending, (state) => {
        state.NewsArticlesSearchAuthorLoading = true;
      })
      .addCase(retrieveNewsArticlesSearchAuthor.fulfilled, (state, action) => {
        if (action.payload) {
          state.NewsArticlesSearchAuthorLoading = false;
          state.NewsArticlesSearchAuthorData = action.payload as NewsArticlesSearchAuthorTypes[];
        }
      })
      .addCase(retrieveNewsArticlesSearchAuthor.rejected, (state, { payload }) => {
        state.NewsArticlesSearchAuthorLoading = false;
        state.NewsArticlesSearchAuthorError = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default newsArticlesSearchAuthorSlice.reducer;
