import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../../errors/api-error-handler";
import { ForAuthorsInitialStateType, ForAuthorsTypes } from "../../../../models/news-articles/regulatory-focus/for-authors-model";
import ForAuthorsServices from "../../../../services/news-articles/regulatory-focus/for-authors.service";

const initialState: ForAuthorsInitialStateType = {
    ForAuthorsData: [],
    ForAuthorsLoading: true,
    ForAuthorsError: undefined,
};



export const retrieveForAuthors = createAsyncThunk(
  "ForAuthors/retrieve",
  async (__data,  { rejectWithValue }) => {
    try {
        const response = await ForAuthorsServices.getAllRegulatoryFocusForAuthors()
        return [response.data];
    }catch (error: any) {
        if (!error.response) {
            throw error
          }
        return rejectWithValue(error.response.status) 
    }   
})
  

const ForAuthorsSlice = createSlice({
  name: "NewsArticlesSearc",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveForAuthors.pending, (state) => {
        state.ForAuthorsLoading = true;
      })
      .addCase(retrieveForAuthors.fulfilled, (state, action) => {
        if (action.payload) {
          state.ForAuthorsLoading = false;
          state.ForAuthorsData = action.payload as ForAuthorsTypes[];
        }
      })
      .addCase(retrieveForAuthors.rejected, (state, { payload }) => {
        state.ForAuthorsLoading = false;
        state.ForAuthorsError = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default ForAuthorsSlice.reducer;
