import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import {  LatestArticlesResponse, RightSideLatestArticlesInitialStateType } from "../../../models/latest-articles-model";
import { RightSideTrailInitialStateType, RightSideTrailNewsType, RightSideTrailType } from "../../../models/right-side-trail-model";
import NewsArticlesRightSideNewsService from '../../../services/right-side/news-articles-right-side-news.service';

const initialState: RightSideTrailInitialStateType = {
    rightSideFeaturedBooksData: [],
    rightSideFeaturedLearningData: [],
    rightSideTrendingNewsData: [],
    rightSidePopularEventsData: [],
    Loading: false,
    Error: undefined,
};

export const retrieveNewsArticlesRightSideNews = createAsyncThunk('NewsArticlesRightSideNews/retrieve',
    async (productPath: string, { rejectWithValue }) => {
        try {

            const response = await NewsArticlesRightSideNewsService.getNewsArticlesRightSideNewsService(productPath)
           
            return [response.data]

        } catch (error: any) {
            if (!error.response) {
                throw error
            }
            return rejectWithValue(error.response.status)
        }
    }
)

export const cleanNewsArticlesRightSideNews = createAsyncThunk("NewsArticlesRightSideNews/clean", () => { return; });

const NewsArticlesRightSideNewsSlice = createSlice({
    name: 'NewsArticlesRightSideNews',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(cleanNewsArticlesRightSideNews.fulfilled, (state) => {
                state.Loading = false;
                state.rightSideTrendingNewsData = [];
                state.Error = undefined;
              })
            .addCase(retrieveNewsArticlesRightSideNews.pending, (state) => {
                state.Loading = true;
            })
            .addCase(retrieveNewsArticlesRightSideNews.fulfilled, (state, action) => {
                if (action.payload) {
                    state.Loading = false;
                    state.rightSideTrendingNewsData = action.payload as RightSideTrailNewsType[];
                }
            })
            .addCase(retrieveNewsArticlesRightSideNews.rejected, (state, { payload }) => {
                state.Loading = false;
                state.Error = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default NewsArticlesRightSideNewsSlice.reducer;
