import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { UrlRedirectInitialStateType } from "../../../models/url-redirect.model";
import UrlRedirectService from "../../../services/url-redirect.service";

const initialState: UrlRedirectInitialStateType = {
  data: "",
  loading: false,
  error: undefined,
};

export const retrieveUrlRedirect = createAsyncThunk("urlRedirect/retrieve", async (urlPath: string) => {
  const service = new UrlRedirectService();

  const response = await service.getRedirectUrl(urlPath);
  return response.data;
});

const urlRedirectSlice = createSlice({
  name: "urlRedirect",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveUrlRedirect.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveUrlRedirect.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as string;
        }
      })
      .addCase(retrieveUrlRedirect.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default urlRedirectSlice.reducer;
