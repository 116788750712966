import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { Suspense, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import LoadingComp from "./components/re-usable/loading/loading";
import DefaultLayout from "./containers/default-layout";

import { HelmetProvider } from "react-helmet-async";
import Convergence2023Layout from "./containers/convergence-2023-layout";
import Convergence2024Layout from "./containers/convergence-2024-layout";
import Europe2023Layout from "./containers/europe-2023-layout";
import Europe2024Layout from "./containers/europe-2024-layout";
import Europe2025Layout from "./containers/europe-2025-layout";
import EUCRMConferenceLayout from "./containers/eucrm-layout";
import GRSConferenceLayout from "./containers/grs-layout";
import RIConferenceLayout from "./containers/riconf-layout";
import EUComboConferenceLayout from "./containers/eu-combo-layout";
import RPFConferenceLayout from "./containers/rpf-layout";
import CertificationsLayout from "./containers/certifications-layout";

import PreviewLayout from "./containers/preview-layout";
import useAuth from "./hooks/useAuth";
import useRawRedirectUrls from "./hooks/useRawRedirectUrls";
import Sitemap from "./pages/sitemap";
import { logout } from "./redux/slice/sso/authentication-slice";
import { useAppDispatch } from "./redux/store";
import { RemoveTrailingSlash } from "./routes/remove-trailing-slash";
import { localStorageHelper } from "./helpers/local-storage-helper";

function App() {
  const { user, loginUser, logoutUser } = useAuth();
  const { callRedirectListApi } = useRawRedirectUrls();
  const gtmParams = { id: "GTM-NGHF4D" };

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user) {
      loginUser(user);
    } else {
      logoutUser();
    }
  }, []);

  const onIdle = () => {
    const user = localStorageHelper.get("user");

    if (user) {
      dispatch(logout(user.accessToken)).then(() => {
        logoutUser();
        window.location.href = "/";
      });
    }
  };

  useIdleTimer({
    onIdle,
    timeout: 3600_000,
    throttle: 500,
  });

  useEffect(() => {
    callRedirectListApi();
  }, [])


  return (
    <GTMProvider state={gtmParams}>
      <HelmetProvider>
        <BrowserRouter>
          {/* <ScrollToTop /> */}
          <Suspense fallback={<LoadingComp />}>
            <RemoveTrailingSlash />
            <Routes>
              <Route path="/europe-2023/*" element={<Europe2023Layout />} />
              <Route path="/europe-2024/*" element={<Europe2024Layout />} />
              <Route path="/europe-2025/*" element={<Europe2025Layout />} />
              <Route path="/convergence-2023/*" element={<Convergence2023Layout />} />
              <Route path="/convergence-2024/*" element={<Convergence2024Layout />} />
              <Route path="/convergence/*" element={<Convergence2024Layout />} />
              <Route path="/eucrps/*" element={<EUCRMConferenceLayout />} />
              <Route path="/grs/*" element={<GRSConferenceLayout />} />
              <Route path="/riconf/*" element={<RIConferenceLayout />} />
              <Route path="/eucomboprods/*" element={<EUComboConferenceLayout />} />
              <Route path="/regpolicyforum/*" element={<RPFConferenceLayout />} />
              <Route path="/certifications/*" element={<CertificationsLayout />} />
              <Route path="/sitemap" element={<Sitemap />} />
              <Route path="/cmsctx/*" element={<PreviewLayout />} />
              <Route path="*" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </HelmetProvider>
    </GTMProvider>
  );
}

export default App;
