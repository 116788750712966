import React, { ReactNode } from "react";
import { Navigate } from 'react-router-dom';

import PrivateRoute from "../routes/private-routes";


const EuropeHome = React.lazy(() => import('../pages/europe-2023/europe-2023-home'));
const EuropeAbout = React.lazy(() => import('../pages/europe-2023/europe-2023-about'));
const EuropeDelegateProfile = React.lazy(() => import('../pages/europe-2023/europe-2023-delegate-profile'));
const EuropeHotelTravel = React.lazy(() => import('../pages/europe-2023/europe-2023-hotel-travel'));
const EuropeOverview = React.lazy(() => import('../pages/europe-2023/europe-2023-overview'));
const EuropeSchedule = React.lazy(() => import('../pages/europe-2023/europe-schedule'))
const EuropeRegister = React.lazy(() => import('../pages/europe-2023/europe-2023-register'));
const EuropeSponsorshipInformation = React.lazy(() => import('../pages/europe-2023/europe-2023-sponsorship-information'));
const EuropeSocial = React.lazy(() => import('../pages/europe-2023/europe-2023-social'))
const EuropeRecap = React.lazy(() => import('../pages/europe-2023/europe-recap'))
const EuroCodeOfConductIncidentReport = React.lazy(() => import('../pages/europe-2023/euro-2023-Code-of-Conduct-Incident-Report'))
const EuropeCventRegistration = React.lazy(() => import('../pages/europe-2023/europe-2023-cvent-registration'))
const EuropeProposals = React.lazy(() => import('../pages/europe-2023/europe-call-for-proposals'));

const PageNotFound = React.lazy(() => import("../pages/errors/page-not-found"));

export type route = {
    component: ReactNode;
    exact: boolean | undefined;
    name?: string | undefined;
    path: string;
    routes?: route[];
};

const EuropeRouter: route[] = [
    { path: '/', exact: true, name: 'Europe 2023 Home', component: <Navigate to={`/europe-2023/recap${window.location.search}`} />},
    { path: '/home', exact: true, name: 'Europe 2023 Home', component: <EuropeHome /> },
    { path: '/about', exact: true, name: 'Europe 2023 About', component: <EuropeAbout /> },
    { path: '/register', exact: true, name: 'Europe 2023 About', component: <EuropeRegister /> },
    { path: '/overview', exact: true, name: 'Europe 2023 About', component: <EuropeOverview /> },
    { path: '/schedule', exact: true, name: 'Europe 2023 About', component: <EuropeSchedule /> },
    { path: '/hotel-travel', exact: true, name: 'Europe 2023 About', component: <EuropeHotelTravel /> },
    { path: '/delegate-profile', exact: true, name: 'Europe 2023 About', component: <EuropeDelegateProfile /> },
    { path: '/sponsorship-information', exact: true, name: 'Europe 2023 About', component: <EuropeSponsorshipInformation /> },
    { path: '/social', exact: true, name: 'Social', component: <EuropeSocial /> },
    { path: '/recap', exact: true, name: 'Recap', component: <EuropeRecap /> },
    { path: '/code-of-conduct-incident-report', exact: true, name: 'code-of-conduct-incident-report', component: <EuroCodeOfConductIncidentReport /> },
    { path: '/cvent-registration', exact: true, name: 'Europe 2023 Cvent Registeration', component: <PrivateRoute><EuropeCventRegistration /></PrivateRoute> },
    { path: '/2024-call-for-proposals', exact: true, name: 'Europe 2024 Call for Proposals', component: <EuropeProposals /> },

    // Alternate URLs

    // End Alternate URLs

    { path: '/*', exact: true, component: <PageNotFound /> },

]
export default EuropeRouter;
