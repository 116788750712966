import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { ImpexiumSubscriptionsInitialStateType, ImpexiumSubscriptionsResponse } from "../../../models/impexium/impexium-subscriptions.model";
import ImpexiumSubscriptionService from "../../../services/impexium/impexium-subscriptions.service";

const initialState: ImpexiumSubscriptionsInitialStateType = {
  data: {} as ImpexiumSubscriptionsResponse,
  loading: false,
  error: undefined,
};

export const retrieveImpexiumSubscriptions = createAsyncThunk("impexiumSubscriptions/retrieve", async (userId: string) => {
  const service = new ImpexiumSubscriptionService();

  const response = await service.getPreferenceSubscriptions(userId);
  return response.data;
});

export const cleanImpexiumSubscriptions = createAsyncThunk("impexiumSubscriptions/clean", async () => {
  return;
});

const impexiumSubscriptionsSlice = createSlice({
  name: "impexiumSubscriptions",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveImpexiumSubscriptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveImpexiumSubscriptions.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as ImpexiumSubscriptionsResponse;
        }
      })
      .addCase(retrieveImpexiumSubscriptions.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      })
      .addCase(cleanImpexiumSubscriptions.fulfilled, (state) => {
        state.data = {} as ImpexiumSubscriptionsResponse;
        state.loading = false;
        state.error = undefined;
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default impexiumSubscriptionsSlice.reducer;
