import axios from "axios";

type RequestType = {
  url: string,
  hliamKey: string,
  hlPassword: string
}

class ExternalApiCallService {
  private createAxios(url: string) {
    return axios.create({
      baseURL: url,
      headers: {
        "Content-type": "application/json",
      },
    });
  }

  callExternalApi(request: RequestType){
    // BaseUrl: grabs the string until the end of the last /
    // Api: grabs the string after the last /
    let regex = request.url.match(/(?<BaseUrl>^.*\/)(?<Api>.*)/); 
    let groups = regex?.groups;
    let baseUrl = groups?.BaseUrl ?? "";
    let api = groups?.Api ?? "";

    if (baseUrl === "" || api === "")
        return;

    let http = this.createAxios(baseUrl);
    return http.get(api, {
      headers: {
        hliamKey: request.hliamKey,
        hlPassword: request.hlPassword
      }
    });
  }
}

export default new ExternalApiCallService();