import { RapsEuropeTypes, RapsEuropeInitialStateType } from "../../../models/raps-europe-model";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PageMenuService from "../../../services/page-menu.service";

const initialState: RapsEuropeInitialStateType = {
  RapsEuropeData: [],
  PageMenuEurope2023BannerData: [],
  RapsEuropeLoading: true,
  RapsEuropeError: undefined,
  fromSSR: false,
  pageNotFound: false,
};



export const retrievePageMenuEurope2023Banner = createAsyncThunk("PageMenu/retrieve", async (__data, { rejectWithValue }) => {
  try {
    const response = await PageMenuService.getPageMenu('europe-2023');
    return [response.data];
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.status);
  }
});

const PageMenuEurope2023BannerSlice = createSlice({
  name: "page-menu",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrievePageMenuEurope2023Banner.pending, (state) => {
        state.RapsEuropeLoading = true;
      })
      .addCase(retrievePageMenuEurope2023Banner.fulfilled, (state, action) => {
        if (action.payload) {
          state.RapsEuropeLoading = false;
          state.PageMenuEurope2023BannerData = action.payload as RapsEuropeTypes[];
        }
      })
      .addCase(retrievePageMenuEurope2023Banner.rejected, (state, { payload }) => {
        state.RapsEuropeLoading = false;
        state.RapsEuropeError = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default PageMenuEurope2023BannerSlice.reducer;
