import http from "../http-common"

class EditorialAdvisoryCommitteeServices {
    getAllEditorialAdvisoryCommittee(pathId:string) {
        return http.get(`/editorial-advisory-committee/${pathId}`)
    }
}


export default new EditorialAdvisoryCommitteeServices();

