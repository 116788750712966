import http from "../../../http-common"
import { pageSizeType, sortByType } from "../../../redux/slice/news-articles/news-articles-search-slice";

class RegulatoryFocusLatestFeaturesServices {
    getAllRegulatoryFocusLatestFeatures() {
        return http.get('/regulatory-focus/latest-features')
    }
}


export default new RegulatoryFocusLatestFeaturesServices();