import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import { RightSideTrailType, RightSideTrailInitialStateType } from "../../../models/right-side-trail-model";
import ProductRightSideLearningService from '../../../services/right-side/product-right-side-learning.service';

const initialState: RightSideTrailInitialStateType = {
    rightSideFeaturedBooksData: [],
    rightSideFeaturedLearningData: [],
    rightSideTrendingNewsData: [],
    rightSidePopularEventsData: [],
    Loading: true,
    Error: undefined,
};


export const retrieveProductRightSideLearning = createAsyncThunk('ProductRightSideLearning/retrieve',
    async (productPath: string, { rejectWithValue }) => {
        try {

            const response = await ProductRightSideLearningService.getProductRightSideLearningService(productPath)
            return [response.data]

        } catch (error: any) {
            if (!error.response) {
                throw error
            }
            return rejectWithValue(error.response.status)
        }
    }
)

const ProductRightSideLearningSlice = createSlice({
    name: 'ProductRightSideLearning',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveProductRightSideLearning.pending, (state) => {
                state.Loading = true;
            })
            .addCase(retrieveProductRightSideLearning.fulfilled, (state, action) => {
                if (action.payload) {
                    state.Loading = false;
                    state.rightSideFeaturedLearningData = action.payload as RightSideTrailType[];
                }
            })
            .addCase(retrieveProductRightSideLearning.rejected, (state, { payload }) => {
                state.Loading = false;
                state.Error = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default ProductRightSideLearningSlice.reducer;
