import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactNode } from "react";
import LoadingComp from "../../../components/re-usable/loading/loading";

interface Props {
  children: ReactNode;
}

const LoggedInRedirectCheckComponent = ({ children }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { from } = location.state || { from: { pathname: "/member-dashboard" } };
  const { search } = location;
  const query = new URLSearchParams(search.toLowerCase());

  const { checkLogin } = useSelector((state: RootState) => state.loggedIn);

  if (checkLogin.isLoggedIn) {
    let returnUrl = query.get("returnurl") || null;
    if (returnUrl !== null) {
      window.location.replace(returnUrl);
    } else {
      navigate(from);
    }
  }

  if (checkLogin.isLoggedIn) return <LoadingComp />

  return <>{children}</>
};

export default LoggedInRedirectCheckComponent;
