import { apiErrorHandler } from './../../errors/api-error-handler';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NotificationServices from "../../services/notification-model.service"
import { notificationInitialStateType, notificationModel } from '../../models/notification-model';

const initialState: notificationInitialStateType = {
    notificationData: [],
    notificationLoading: true,  
    notificationError: undefined,
} ;

export const retrieveNotification = createAsyncThunk('notification/retrieve',
    async (data, { rejectWithValue }) => {
        try {
            const response = await NotificationServices.getAllNotificationModel();
         return [response.data]
         
        } catch (error: any) {
            if (!error.response) {
                throw error
              }
            return rejectWithValue(error.response.status)
            
        }
        
    }
)



const notificationSlice = createSlice({
    name: 'primaryMenu',
    initialState: initialState,
    reducers: {


    },
    extraReducers: (builder) => {
        builder
            .addCase(retrieveNotification.pending, (state) => {
                    state.notificationLoading = true;
            })
            .addCase(retrieveNotification.fulfilled, (state, action) => {
                if(action.payload){
                    state.notificationLoading = false;
                    state.notificationData = action.payload as notificationModel[];
                }   
            })        
            .addCase(retrieveNotification.rejected, (state, {payload}) => {
                    state.notificationLoading = false;
                    state.notificationError = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default notificationSlice.reducer;
