import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../../errors/api-error-handler";
import { FeatureFocusInitialStateType, FeatureFocusTypes } from "../../../../models/news-articles/regulatory-focus/feature-focus-model";
import FeatureFocusServices from "../../../../services/news-articles/regulatory-focus/feature-focus.service";

const initialState: FeatureFocusInitialStateType = {
    FeatureFocusData: [],
    FeatureFocusLoading: true,
    FeatureFocusError: undefined,
};



export const retrieveFeatureFocus = createAsyncThunk(
  "FeatureFocus/retrieve",
  async (__data,  { rejectWithValue }) => {
    try {
        const response = await FeatureFocusServices.getAllRegulatoryFocusFeatureFocus();
        return [response.data];
    }catch (error: any) {
        if (!error.response) {
            throw error
          }
        return rejectWithValue(error.response.status) 
    }   
})
  

const FeatureFocusSlice = createSlice({
  name: "NewsArticlesSearc",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveFeatureFocus.pending, (state) => {
        state.FeatureFocusLoading = true;
      })
      .addCase(retrieveFeatureFocus.fulfilled, (state, action) => {
        if (action.payload) {
          state.FeatureFocusLoading = false;
          state.FeatureFocusData = action.payload as FeatureFocusTypes[];
        }
      })
      .addCase(retrieveFeatureFocus.rejected, (state, { payload }) => {
        state.FeatureFocusLoading = false;
        state.FeatureFocusError = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default FeatureFocusSlice.reducer;
