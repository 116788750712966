import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { CalloutsInitialStateType, CalloutsResponse } from "../../../models/home/callouts.model";
import CalloutsService from "../../../services/home/callouts.service";

const initialState: CalloutsInitialStateType = {
  data: {} as CalloutsResponse,
  loading: true,
  error: undefined,
};

export const retrieveCallouts = createAsyncThunk("callouts/retrieve", async () => {
  const service = new CalloutsService();

  const response = await service.getSimpleCallouts();
  return response.data;
});

const calloutsSlice = createSlice({
  name: "callouts",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveCallouts.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveCallouts.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as CalloutsResponse;
        }
      })
      .addCase(retrieveCallouts.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default calloutsSlice.reducer;
