import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import {
  SiteMapInitialStateType,
  SiteMapType,
} from "../../models/sitemap-model";
import SiteMapService from "../../services/sitemap.service";

const initialState: SiteMapInitialStateType = {
  SiteMapData: [],
  SiteMapLoading: true,
  SiteMapError: undefined,
};

export const retrieveSiteMap = createAsyncThunk(
  "SiteMap/retrieve",
  async (___data) => {
    const response = await SiteMapService.getSiteMap()
    return [response.data];
  }
);

const SiteMapSlice = createSlice({
  name: "SiteMap",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveSiteMap.pending, (state) => {
        state.SiteMapLoading = true;
      })
      .addCase(retrieveSiteMap.fulfilled, (state, action) => {
        if (action.payload) {
          state.SiteMapLoading = false;
          state.SiteMapData = action.payload as SiteMapType[];
        }
      })
      .addCase(retrieveSiteMap.rejected, (state, { payload }) => {
        state.SiteMapLoading = false;
        state.SiteMapError = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default SiteMapSlice.reducer;
