import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import { EventDetailsInitialStateType, EventDetailsType } from '../../../models/events/event-details-modal';
import { RightSideTrailInitialStateType, RightSideTrailType } from "../../../models/right-side-trail-model";
import EventRightSideLearningService from '../../../services/right-side/event-right-side-learning.service';

const initialState: RightSideTrailInitialStateType = {
    rightSideFeaturedBooksData: [],
    rightSideFeaturedLearningData: [],
    rightSideTrendingNewsData: [],
    rightSidePopularEventsData: [],
    Loading: true,
    Error: undefined,
};


export const retrieveEventRightSideLearning = createAsyncThunk('EventRightSideLearning/retrieve',
    async (eventPath: string, { rejectWithValue }) => {
        try {

            const response = await EventRightSideLearningService.getEventRightSideLearningService(eventPath)
            return [response.data]

        } catch (error: any) {
            if (!error.response) {
                throw error
            }
            return rejectWithValue(error.response.status)
        }
    }
)

const EventRightSideLearningSlice = createSlice({
    name: 'EventRightSideLearning',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveEventRightSideLearning.pending, (state) => {
                state.Loading = true;
            })
            .addCase(retrieveEventRightSideLearning.fulfilled, (state, action) => {
                if (action.payload) {
                    state.Loading = false;
                    state.rightSideFeaturedLearningData = action.payload as RightSideTrailType[];
                }
            })
            .addCase(retrieveEventRightSideLearning.rejected, (state, { payload }) => {
                state.Loading = false;
                state.Error = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default EventRightSideLearningSlice.reducer;
