import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import { TypeFiltersInitialStateType, TypeFiltersType } from '../../../models/events/type-filters-model'
import TypeFiltersService from '../../../services/events/type-filters';

const initialState: TypeFiltersInitialStateType = {
    TypeFiltersData: [],
    TypeFiltersLoading: true,
    TypeFiltersError: undefined
};


export const retrieveTypeFilters = createAsyncThunk('TypeFilters/retrieve',
    async (__data, { rejectWithValue }) => {
        try {

            const response = await TypeFiltersService.getTettypeFilters()
            return [response.data]

        } catch (error: any) {
            if (!error.response) {
                throw error
            }
            return rejectWithValue(error.response.status)
        }
    }
)

const TypeFiltersSlice = createSlice({
    name: 'TypeFilters',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveTypeFilters.pending, (state) => {
                state.TypeFiltersLoading = true;
            })
            .addCase(retrieveTypeFilters.fulfilled, (state, action) => {
                if (action.payload) {
                    state.TypeFiltersLoading = false;
                    state.TypeFiltersData = action.payload as TypeFiltersType[];
                }
            })
            .addCase(retrieveTypeFilters.rejected, (state, { payload }) => {
                state.TypeFiltersLoading = false;
                state.TypeFiltersError = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default TypeFiltersSlice.reducer;
