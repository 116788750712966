import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import {
  RegulatoryExchangeInitialStateType,
  RegulatoryExchangeType,
} from "../../models/regulatory-exchange";
import RegulatoryExchangeService from "../../services/regulatory-exchange";

const initialState: RegulatoryExchangeInitialStateType = {
  RegulatoryExchangeData: [],
  RegulatoryExchangeLoading: true,
  RegulatoryExchangeError: undefined,
};

export const retrieveRegulatoryExchange = createAsyncThunk(
  "RegulatoryExchange/retrieve",
  async () => {
    const response = await RegulatoryExchangeService.getAllRegulatoryExchange();
    
    return [response.data];
  }
);

const regulatoryExchangeSlice = createSlice({
  name: "RegulatoryExchange",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveRegulatoryExchange.pending, (state) => {
        state.RegulatoryExchangeLoading = true;
      })
      .addCase(retrieveRegulatoryExchange.fulfilled, (state, action) => {
        if (action.payload) {
          state.RegulatoryExchangeLoading = false;
          state.RegulatoryExchangeData = action.payload as RegulatoryExchangeType[];
        }
      })
      .addCase(retrieveRegulatoryExchange.rejected, (state, { payload }) => {
        state.RegulatoryExchangeLoading = false;
        state.RegulatoryExchangeError = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default regulatoryExchangeSlice.reducer;
