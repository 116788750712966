import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import {
  EditorialAdvisoryCommitteeInitialStateType,
  EditorialAdvisoryCommitteeTypes,
} from "../../models/editorial-advisory-committee-model";
import EditorialAdvisoryCommitteeService from "../../services/editorial-advisory-committee.service";
import PageTabService from "../../services/page-tab-service";

const initialState: EditorialAdvisoryCommitteeInitialStateType = {
  EditorialAdvisoryCommitteeData: [],
  EditorialAdvisoryCommitteeLoading: true,
  EditorialAdvisoryCommitteeError: undefined,
};

export const retrieveEditorialAdvisoryCommittee = createAsyncThunk(
  "EditorialAdvisoryCommittee/retrieve",
  async (path: string) => {
    const response = await PageTabService.getPageTabs(path);
    return [response.data];
  }
);

const editorialAdvisoryCommitteeSlice = createSlice({
  name: "editorial-advisory-committee",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveEditorialAdvisoryCommittee.pending, (state) => {
        state.EditorialAdvisoryCommitteeLoading = true;
      })
      .addCase(retrieveEditorialAdvisoryCommittee.fulfilled, (state, action) => {
        if (action.payload) {
          state.EditorialAdvisoryCommitteeLoading = false;
          state.EditorialAdvisoryCommitteeData = action.payload as EditorialAdvisoryCommitteeTypes[];
        }
      })
      .addCase(retrieveEditorialAdvisoryCommittee.rejected, (state, { payload }) => {
        state.EditorialAdvisoryCommitteeLoading = false;
        state.EditorialAdvisoryCommitteeError = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default editorialAdvisoryCommitteeSlice.reducer;
