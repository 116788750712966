import http from "../http-common"

class PageTabService {
    getPageTabs(pathId:string) {
        return http.get(`/page-tabs?path=${pathId}`)
    }
}


export default new PageTabService();

