import { apiErrorHandler } from './../../errors/api-error-handler';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ConvergenceFooterService from "../../services/convergence-footer.service"
import { ConvergenceFooterInitialStateType, ConvergenceFooterType } from '../../models/convergence-footer-model';

const initialState: ConvergenceFooterInitialStateType = {
    ConvergenceFooterData: [],
    ConvergenceFooterLoading: true,  
    ConvergenceFooterError: undefined,
} ;

export const retrieveConvergenceFooter = createAsyncThunk('ConvergenceFooter/retrieve',
    async (pathName: string, { rejectWithValue }) => {
        try {
            const response = await ConvergenceFooterService.getAllconvergenceFooter(pathName);
            return [response.data]
        
        } catch (error: any) {
            if (!error.response) {
                throw error
              }
            return rejectWithValue(error.response.status)
            
        }
        
    }
)

const ConvergenceFooterSlice = createSlice({
    name: 'cookie-law',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(retrieveConvergenceFooter.pending, (state) => {
                    state.ConvergenceFooterLoading = true;
            })
            .addCase(retrieveConvergenceFooter.fulfilled, (state, action) => {
                if(action.payload){
                    state.ConvergenceFooterLoading = false;
                    state.ConvergenceFooterData = action.payload as ConvergenceFooterType[];
                }   
            })        
            .addCase(retrieveConvergenceFooter.rejected, (state, {payload}) => {
                    state.ConvergenceFooterLoading = false;
                    state.ConvergenceFooterError = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default ConvergenceFooterSlice.reducer;
