import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import {
  RAPSHighlightsInitialStateType,
  RAPSHighlightsType,
} from "../../models/raps-highlights-model";
import RAPSHighlightsService from "../../services/raps-highlights.service";

const initialState: RAPSHighlightsInitialStateType = {
  rapsHighlights: [],
  loading: true,
  error: undefined,
};

export const retrieveRAPSHighlights = createAsyncThunk(
  "rapsHighlights/retrieve",
  async (path?: string) => {
    const response = await RAPSHighlightsService.getAllPromotions(path);
    return [...response.data.menudata];
  }
);

const rapsHighlightsSlice = createSlice({
  name: "rapsHighlights",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveRAPSHighlights.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveRAPSHighlights.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.rapsHighlights = action.payload as RAPSHighlightsType[];
        }
      })
      .addCase(retrieveRAPSHighlights.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default rapsHighlightsSlice.reducer;
