import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { UserInfoInitialStateType, UserInfoResponse } from "../../../models/user.model";
import UserInfoService from "../../../services/sso/userinfo.service";

const initialState: UserInfoInitialStateType = {
  data: {} as UserInfoResponse,
  loading: false,
  error: undefined,
};

export const retrieveUserInfo = createAsyncThunk("userInfo/retrieve", async () => {
  const service = new UserInfoService();

  const response = await service.getUserInfo();
  return response.data;
});

const userInfoSlice = createSlice({
  name: "userInfo",
  initialState: initialState,
  reducers: {
    logoutUser(state) {
      state.data = {} as UserInfoResponse;
      state.loading = false;
      state.error = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(retrieveUserInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveUserInfo.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as UserInfoResponse;
        }
      })
      .addCase(retrieveUserInfo.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export const { logoutUser } = userInfoSlice.actions;
export default userInfoSlice.reducer;
