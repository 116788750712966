import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { ImpexiumUserInfoInitialStateType, ImpexiumUserInfoResponse } from "../../../models/impexium/impexium-user-info.model";
import ImpexiumUserInfoService from "../../../services/impexium/impexium-user-info.service";

const initialState: ImpexiumUserInfoInitialStateType = {
  data: {} as ImpexiumUserInfoResponse,
  loading: true,
  error: undefined,
};

export const retrieveImpexiumUserInfo = createAsyncThunk("impexiumUserInfo/retrieve", async (email: string) => {
  const service = new ImpexiumUserInfoService();

  const response = await service.getUserByEmail(email);
  return response.data;
});

export const cleanImpexiumUserInfo = createAsyncThunk("impexiumUserInfo/clean", async () => {
  return;
});

const impexiumUserInfoSlice = createSlice({
  name: "impexiumUserInfo",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveImpexiumUserInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveImpexiumUserInfo.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as ImpexiumUserInfoResponse;
        }
      })
      .addCase(retrieveImpexiumUserInfo.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      }).addCase(cleanImpexiumUserInfo.fulfilled, (state) => {
        state.data = {} as ImpexiumUserInfoResponse;
        state.loading = true;
        state.error = undefined;
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default impexiumUserInfoSlice.reducer;
