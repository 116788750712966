import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import {
    HomeRegulatoryExchangeInitialStateType,
    HomeRegulatoryExchangeType,
} from "../../models/home-regulatory-exchange-model";
import {
  LatestDiscussionInitialStateType,
  LatestDiscussionResponse,
} from "../../models/external/latest-discussion-model";
import HomeRegulatoryExchangeService from "../../services/home-regulatory-exchange.service";

const initialState: LatestDiscussionInitialStateType = {
//const initialState: HomeRegulatoryExchangeInitialStateType = {
  data: [],
  loading: true,
  error: undefined,
};

export const retrieveHomeRegulatoryExchange = createAsyncThunk(
  "homeRegulatoryExchange/retrieve",
  async () => {
    const response = await HomeRegulatoryExchangeService.getHomeRegulatoryExchange();
    return [...response.data.regulatoryMenuData];
  }
);

const homeRegulatoryExchangeSlice = createSlice({
  name: "homeRegulatoryExchange",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveHomeRegulatoryExchange.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveHomeRegulatoryExchange.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as LatestDiscussionResponse[];
          //state.data = action.payload as HomeRegulatoryExchangeType[];
        }
      })
      .addCase(retrieveHomeRegulatoryExchange.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default homeRegulatoryExchangeSlice.reducer;
