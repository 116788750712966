import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import { CventMemberTypeConvertInitialStateType } from "../../models/cvent-member-type-convert.model";
import CventMemberConvertService from "../../services/cvent-member-type-convert.service";

const initialState: CventMemberTypeConvertInitialStateType = {
  data: "",
  loading: true,
  error: undefined,
};

interface CventMemberConvertRequest {
    memberType: string,
    isActive: boolean,
    eCode: string
}

export const retrieveCventMemberConvert = createAsyncThunk("cventMemberConvert/retrieve", async (request: CventMemberConvertRequest) => {
  const service = new CventMemberConvertService();

  const response = await service.getConvertedMember(request.memberType, request.isActive, request.eCode);
  return response.data;
});

const cventMemberConvertSlice = createSlice({
  name: "cventMemberConvert",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveCventMemberConvert.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveCventMemberConvert.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as string;
        }
      })
      .addCase(retrieveCventMemberConvert.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default cventMemberConvertSlice.reducer;
