import http from "../http-common";

class ArticleDetaisl{
  getAllarticleDetaisl(pathName: string) {
    const queryParams = new URLSearchParams(window.location.search);
    const isPreview = queryParams.get("isPreview") && queryParams.get("wg");
    let addQuery = isPreview ? `&isPreview=true&wg=${queryParams.get("wg")}` : "";
    return http.get(`/articles?path=${pathName}${addQuery}`);
  }
}

export default new ArticleDetaisl();
