import axios from "axios";
import http from "../../http-common"
import { pageSizeType, sortByType } from "../../redux/slice/news-articles/news-articles-search-slice";

class NewsArticlesSearchServices {
    getAllNewsArticles(keyword:string,fromDate: string, toDate: string, categoryID: number, authorCode: string, pageSize:pageSizeType, sortBy:sortByType, pageNumber:number, documentTags: string) {
        return http.get(`news-articles-search?keyword=${keyword}&fromDate=${fromDate}&todate=${toDate}&categoryId=${categoryID}&author=${authorCode}&pageSize=${pageSize}&sortBy=${sortBy}&pageNumber=${pageNumber}&DocumentTags=${documentTags}`,
        { cancelToken: axios.CancelToken.source().token }
        )
    }
}


export default new NewsArticlesSearchServices();