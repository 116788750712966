import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import { AllBooksInitialStateType, AllBooksType } from '../../../models/education/all-books-model';
import AllBooksService from '../../../services/education/all-books.service';

const initialState: AllBooksInitialStateType = {
    AllBooksData: [],
    AllBooksLoading: true,
    AllBooksError: undefined
};

export const retrieveAllBooks = createAsyncThunk('AllBooks/retrieve',
    async (__data , { rejectWithValue }) => {
        try {

            const response = await AllBooksService.getAllBooks()
            return [response.data]

        } catch (error: any) {
            if (!error.response) {
                throw error
            }
            return rejectWithValue(error.response.status)
        }
    }
)

const AllBooksSlice = createSlice({
    name: 'AllBooks',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveAllBooks.pending, (state) => {
                state.AllBooksLoading = true;
            })
            .addCase(retrieveAllBooks.fulfilled, (state, action) => {
                if (action.payload) {
                    state.AllBooksLoading = false;
                    state.AllBooksData = action.payload as AllBooksType[];
                }
            })
            .addCase(retrieveAllBooks.rejected, (state, { payload }) => {
                state.AllBooksLoading = false;
                state.AllBooksError = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default AllBooksSlice.reducer;
