import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import { SearchPageTabsInitialStateType, SearchPageTabItems } from '../../../models/search-page/search-page-tabs-model'
import SearchPageTabOptionsService from '../../../services/search-page/search-page-tab-options-service';

const initialState: SearchPageTabsInitialStateType ={
    pageTabsData: [],
    loading: true,
    error: undefined
}

export const retrievePageTabs = createAsyncThunk('searchPageTabs/retrieve',
    async() => {
        const response = await SearchPageTabOptionsService.getPageTabOptions()
        return [response.data]
    }
)

const searchPageTabsSlice = createSlice({
    name: 'searchPageTabs',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrievePageTabs.pending, (state) => {
                state.loading = true;
            })
            .addCase(retrievePageTabs.fulfilled, (state, action) => {
                if(action.payload){
                    state.loading = false;
                    state.pageTabsData = action.payload as SearchPageTabItems[];
                }
            })
            .addCase(retrievePageTabs.rejected, (state, {payload}) =>{
                state.loading = false;
                state.error = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: {entities: any;}) => state.entities;
export default searchPageTabsSlice.reducer;