import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import {
  LatestArticlesInitialStateType,
  LatestArticlesType,
} from "../../models/latest-articles-model";
import LatestArticlesService from "../../services/latest-articles.service";

const initialState: LatestArticlesInitialStateType = {
  trendingNewsData: [],
  loading: false,
  error: undefined,
};

export const retrieveLatestArticles = createAsyncThunk(
  "latestArticles/retrieve",
  async (numberOfArticles: number) => {
    const response = await LatestArticlesService.getLatestArticles(numberOfArticles);
    return [...response.data.articlesList];
  }
);

export const cleanLatestArticles = createAsyncThunk("latestArticles/clean", () => { return; });

const latestArticlesSlice = createSlice({
  name: "latestArticles",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(cleanLatestArticles.fulfilled, (state) => {
        state.loading = false;
        state.trendingNewsData = [];
        state.error = undefined;
      })
      .addCase(retrieveLatestArticles.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveLatestArticles.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.trendingNewsData = action.payload as LatestArticlesType[];
        }
      })
      .addCase(retrieveLatestArticles.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default latestArticlesSlice.reducer;
