import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import { SearchOrderByOptionsInitialStateType, OrderByFields } from '../../../models/search-page/search-order-by-options-model'
import SearchSortByOptionsService from '../../../services/search-page/search-sort-by-options-service';

const initialState: SearchOrderByOptionsInitialStateType ={
    orderByData: [],
    loading: true,
    error: undefined
}

export const retrieveOrderyByOptions = createAsyncThunk('searchOrderBy/retrieve',
    async() => {
        const response = await SearchSortByOptionsService.getSortByOptions()
            return [...response.data]
    }
)

const searchOrdeBySlice = createSlice({
    name: 'searchOrderBy',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveOrderyByOptions.pending, (state) => {
                state.loading = true;
            })
            .addCase(retrieveOrderyByOptions.fulfilled, (state, action) => {
                if(action.payload){
                    state.loading = false;
                    state.orderByData = action.payload as OrderByFields[];
                }
            })
            .addCase(retrieveOrderyByOptions.rejected, (state, {payload}) =>{
                state.loading = false;
                state.error = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: {entities: any;}) => state.entities;
export default searchOrdeBySlice.reducer;