import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import { CatalogPathInitialStateType, CatalogPathType } from '../../../models/education/catalog-path-model';
import CatalogPathService from '../../../services/education/catalog-path.service';

const initialState: CatalogPathInitialStateType = {
    CatalogPathData: [],
    CatalogPathLoading: true,
    CatalogPathError: undefined
};



export const retrieveCatalogPath = createAsyncThunk('CatalogPath/retrieve',
    async (path:string , { rejectWithValue }) => {
        try {

            const response = await CatalogPathService.getCatalogPath(path)
            return [response.data]

        } catch (error: any) {
            if (!error.response) {
                throw error
            }
            return rejectWithValue(error.response.status)
        }
    }
)

const CatalogPathSlice = createSlice({
    name: 'CatalogPath',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveCatalogPath.pending, (state) => {
                state.CatalogPathLoading = true;
            })
            .addCase(retrieveCatalogPath.fulfilled, (state, action) => {
                if (action.payload) {
                    state.CatalogPathLoading = false;
                    state.CatalogPathData = action.payload as CatalogPathType[];
                }
            })
            .addCase(retrieveCatalogPath.rejected, (state, { payload }) => {
                state.CatalogPathLoading = false;
                state.CatalogPathError = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default CatalogPathSlice.reducer;
