import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import ConferenceRoutes from "../routes/riconf-routes";
import LoadingComp from "../components/re-usable/loading/loading";
import Footer from "../components/footer/footer";

const RIConferenceLayout = () => {
  return (
    <>
      <Suspense fallback={<LoadingComp />}>
        <Routes>
          {ConferenceRoutes.map((route, index) => {
            return route.component ? <Route key={index} path={route.path} element={route.component} /> : null;
          })}
        </Routes>
      </Suspense>
      <Footer />
    </>
  );
};

export default RIConferenceLayout;