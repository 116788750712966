import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import { ConvergencePagesMenuInitialStateType, ConvergencePagesMenuType } from '../../../models/convergence/convergence-pages-menu-model';
import ConvergencePageMenuService from '../../../services/convergence/convergence-pages-menu.service';

const initialState: ConvergencePagesMenuInitialStateType = {
    ConvergencePagesMenuData: [],
    ConvergencePagesMenuLoading: true,
    ConvergencePagesMenuError: undefined
};

export const retrieveConvergencePageMenu = createAsyncThunk('ConvergencePageMenu/retrieve',
    async (path: string , { rejectWithValue }) => {
        try {

            const response = await ConvergencePageMenuService.getConvergencePagesMenu(path)
            return [response.data]

        } catch (error: any) {
            if (!error.response) {
                throw error
            }
            return rejectWithValue(error.response.status)
        }
    }
)

const ConvergencePageMenuSlice = createSlice({
    name: 'ConvergencePageMenu',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveConvergencePageMenu.pending, (state) => {
                state.ConvergencePagesMenuLoading = true;
            })
            .addCase(retrieveConvergencePageMenu.fulfilled, (state, action) => {
                if (action.payload) {
                    state.ConvergencePagesMenuLoading = false;
                    state.ConvergencePagesMenuData = action.payload as ConvergencePagesMenuType[];
                }
            })
            .addCase(retrieveConvergencePageMenu.rejected, (state, { payload }) => {
                state.ConvergencePagesMenuLoading = false;
                state.ConvergencePagesMenuError = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default ConvergencePageMenuSlice.reducer;
