import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { ArticlesFiltereds399InitialStateType, ArticlesFiltereds399Types } from "../../../models/news-articles/articles-filtered399-model";
import ArticlesFilteredsServices from "../../../services/news-articles/articles-filtered.service";

const initialState: ArticlesFiltereds399InitialStateType = {
  ArticlesFiltereds399Data: [],
  ArticlesFilteredsLoading: true,
  ArticlesFilteredsError: undefined,
};

type QueryParamsType = {
  isInclude: boolean,
  noOfArticles: number
}

export const retrieveArticlesFiltereds399 = createAsyncThunk(
  "ArticlesFiltereds399/retrieve",
  async (queryParams: QueryParamsType, { rejectWithValue }) => {
    try {
      const response = await ArticlesFilteredsServices.getAllArticlesFiltereds('399', queryParams.isInclude, queryParams.noOfArticles);
      return [response.data];
    } catch (error: any) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.status)
    }
  })


const ArticlesFiltereds399Slice = createSlice({
  name: "NewsArticlesSearch399",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveArticlesFiltereds399.pending, (state) => {
        state.ArticlesFilteredsLoading = true;
      })
      .addCase(retrieveArticlesFiltereds399.fulfilled, (state, action) => {
        if (action.payload) {
          state.ArticlesFilteredsLoading = false;
          state.ArticlesFiltereds399Data = action.payload as ArticlesFiltereds399Types[];
        }
      })
      .addCase(retrieveArticlesFiltereds399.rejected, (state, { payload }) => {
        state.ArticlesFilteredsLoading = false;
        state.ArticlesFilteredsError = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default ArticlesFiltereds399Slice.reducer;
