import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import { AccordionInitialStateType, AccordionTypes } from "../../models/accordion-model";
import AccordionService from "../../services/accordion.service";

const initialState: AccordionInitialStateType = {
  AccordionData: [],
  AccordionScheduleData: [],
  AccordionProfileData: [],
  AccordionLoading: true,
  AccordionError: undefined,
};

export const retrieveAccordion = createAsyncThunk(
  "Accordion/retrieve",
  async (path: string) => {
    const response = await AccordionService.getAccordionService(path)
    return [response.data];
  }
);

const AccordionSlice = createSlice({
  name: "board-of-Editors",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveAccordion.pending, (state) => {
        state.AccordionLoading = true;
      })
      .addCase(retrieveAccordion.fulfilled, (state, action) => {
        if (action.payload) {
          state.AccordionLoading = false;
          state.AccordionData = action.payload as AccordionTypes[];
        }
      })
      .addCase(retrieveAccordion.rejected, (state, { payload }) => {
        state.AccordionLoading = false;
        state.AccordionError = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default AccordionSlice.reducer;
