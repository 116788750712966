import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import LoadingComp from "../components/re-usable/loading/loading";
import EuropeRouter from "../routes/europe-2023-routes";
import Footer from "../components/footer/footer";

const EuropeLayout = () => {
  return (
    <>
      <Suspense fallback={<LoadingComp />}>
        <Routes>
          {EuropeRouter.map((route, index) => {
            return route.component ? <Route key={index} path={route.path} element={route.component} /> : null;
          })}
        </Routes>
      </Suspense>
      <Footer />
    </>
  );
};

export default EuropeLayout;
