import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import { ConvergenceAnonymousFormSubmitInitialStateType, ConvergenceAnonymousFormSubmitType } from '../../../models/convergence/convergence-anonymous-form-submit-model';
import EuroAnonymousFormSubmitService from '../../../services/convergence/euro-anonymous-form-submit.service';

const initialState: ConvergenceAnonymousFormSubmitInitialStateType = {
    ConvergenceAnonymousFormSubmitData: [],
    ConvergenceAnonymousFormSubmitLoading: true,
    ConvergenceAnonymousFormSubmitError: undefined
};

export const postEuroAnonymousFormSubmit = createAsyncThunk('EuroAnonymousFormSubmit/retrieve',
    async (formBody: ConvergenceAnonymousFormSubmitType , { rejectWithValue }) => {
        try {

            const response = await EuroAnonymousFormSubmitService.postEuroAnonymousFormSubmitService(formBody)
            return [response.data]

        } catch (error: any) {
            if (!error.response) {
                throw error
            }
            return rejectWithValue(error.response.status)
        }
    }
)

const EuroAnonymousFormSubmitSlice = createSlice({
    name: 'EuroAnonymousFormSubmit',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(postEuroAnonymousFormSubmit.pending, (state) => {
                state.ConvergenceAnonymousFormSubmitLoading = true;
            })
            .addCase(postEuroAnonymousFormSubmit.fulfilled, (state, action) => {
                if (action.payload) {
                    state.ConvergenceAnonymousFormSubmitLoading = false;
                    state.ConvergenceAnonymousFormSubmitData = action.payload as ConvergenceAnonymousFormSubmitType[];
                }
            })
            .addCase(postEuroAnonymousFormSubmit.rejected, (state, { payload }) => {
                state.ConvergenceAnonymousFormSubmitLoading = false;
                state.ConvergenceAnonymousFormSubmitError = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default EuroAnonymousFormSubmitSlice.reducer;
