import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import { eduationPagesInitialStateType, EduationPagesType } from '../../../models/education/education-pages-model';
import EducationPagesService from '../../../services/education/education-pages.service';

const initialState: eduationPagesInitialStateType = {
    eduationPagesData: [],
    eduationPagesLoading: true,
    eduationPagesError: undefined
};


export const retrieveEducationPages = createAsyncThunk('EducationPages/retrieve',
    async (path:string , { rejectWithValue }) => {
        try {

            const response = await EducationPagesService.getEducationPages(path)
            return [response.data]

        } catch (error: any) {
            if (!error.response) {
                throw error
            }
            return rejectWithValue(error.response.status)
        }
    }
)

const EducationPagesSlice = createSlice({
    name: 'EducationPages',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveEducationPages.pending, (state) => {
                state.eduationPagesLoading = true;
            })
            .addCase(retrieveEducationPages.fulfilled, (state, action) => {
                if (action.payload) {
                    state.eduationPagesLoading = false;
                    state.eduationPagesData = action.payload as EduationPagesType[];
                }
            })
            .addCase(retrieveEducationPages.rejected, (state, { payload }) => {
                state.eduationPagesLoading = false;
                state.eduationPagesError = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default EducationPagesSlice.reducer;
