import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PrimaryMenu, PrimaryMenuData } from "../../models/primary-menu-model";
import { retrieveMasterPageConfiguration } from "../../redux/slice/master-page-configuration-slice";
import { logout } from "../../redux/slice/sso/authentication-slice";
import { RootState, useAppDispatch } from "../../redux/store";

import { MenuCtx } from "./menu-context";
//import rfLogo from "../../assets/images/rf-logo.png";
import rapsSiteLogo from "../../assets/images/site-logo.png";
import rapslogoFullcolor from "../../assets/images/rapslogo-fullcolor.png";
import useAuth from "../../hooks/useAuth";
import { retrieveUserInfo } from "../../redux/slice/sso/userinfo.slice";
import { Nav } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { UserInfoDetail } from "../../models/user.model";
import { useDynamicScripts } from "../../hooks/useDynamicScript";

const HeaderNav = (props: { menu: PrimaryMenu[] }) => {
  const auth = useAuth();
  const authLogoutUser = auth.logoutUser;
  const location = useLocation();
  location.pathname = location.pathname.toLowerCase();
  const newspages = location.pathname.split("/")[1];
  const RFQuarterly = location.pathname.split("/")[2];
  const isNewsPages =
    newspages !== "news-and-articles" ||
    RFQuarterly === "rf-quarterly" ||
    location.pathname === "/news-and-articles/copyright-clearance-center" ||
    location.pathname === "/news-and-articles/editorial-advisory-committee" ||
    location.pathname === "/news-and-articles/about-regulatory-focus/author-guidelines" ||
    location.pathname === "/news-and-articles/about-regulatory-focus" ||
    location.pathname === "/news-and-articles/about-regulatory-focus/editorial-calendar"
      ? false
      : true;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { masterPageConfigurationData } = useSelector((state: RootState) => state.masterPageConfiguration);
  const { checkLogin, userData } = useSelector((state: RootState) => state.loggedIn);
  const MenuCtxV = useContext(MenuCtx);
  const [isActiveMenu, setIsActiveMenu] = MenuCtxV.menu;
  const [isActiveLogin, setIsActiveLogin] = MenuCtxV.login;
  const [isActiveSearch, setIsActiveSearch] = MenuCtxV.search;

  const userInfo = useSelector((state: RootState) => state.userInfo);
  const userInfoData = userInfo.data;

  const [savedUserInfoData, setSavedUserInfoData] = useState<UserInfoDetail | undefined>();

  const rfLogo = "https://media.raps.org/m/767f39ce46077593/original/Regulatory-Focus-Logo-JPG-crop.jpeg";

  useEffect(() => {
    dispatch(retrieveMasterPageConfiguration());
  }, [dispatch]);

  useEffect(() => {

  }, [masterPageConfigurationData])

  useDynamicScripts({htmlScriptString: masterPageConfigurationData[0]?.masterPageData[0]?.globalScripts || "", divId: "globalScript"})

  useEffect(() => {
    if (!userInfo.loading && auth.getUser()?.accessToken && userInfo.data?.result === undefined) {
      dispatch(retrieveUserInfo());
    }
  }, [dispatch]);

  useEffect(() => {
    if (userInfo.data?.result !== undefined) {
      setSavedUserInfoData(userInfo.data.result);
    }
  }, [userInfo]);

  const toggleMenu = () => {
    if (MenuCtxV) {
      setIsActiveMenu(!isActiveMenu);
      setIsActiveLogin(false);
      setIsActiveSearch(false);
    }
  };

  const toggleLogin = () => {
    if (MenuCtxV) {
      setIsActiveLogin(!isActiveLogin);
      setIsActiveMenu(false);
      setIsActiveSearch(false);
    }
  };

  const toggleSearch = () => {
    if (MenuCtxV) {
      setIsActiveSearch(!isActiveSearch);
      setIsActiveLogin(false);
      setIsActiveMenu(false);
    }
  };

  const logoutUser = (event: any) => {
    event.preventDefault();
    if (userData) {
      dispatch(logout(userData.accessToken)).then(() => {
        authLogoutUser();
        navigate("/");
        if (window.runGlobalScripts) {
          window.runGlobalScripts();
        }
      });
    }
  };

  const menuState = (item: PrimaryMenuData) => {
    if (item.iconName === "menu_open") {
      return (
        <Link onClick={toggleMenu} to="#">
          <span className={item.iconClass}>{item.iconName}</span>
          <br />
          <div>{item.name}</div>
        </Link>
      );
    }
    if (item.iconName === "search") {
      return (
        <Link onClick={toggleSearch} to="#">
          <span className={item.iconClass}>{item.iconName}</span>
          <br />
          <div>{item.name}</div>
        </Link>
      );
    }
    if (item.iconName === "perm_contact_calendar") {
      return (
        <Link onClick={toggleLogin} to="#">
          <span className={item.iconClass}>{item.iconName}</span>
          <br />
          <div>{item.name}</div>
        </Link>
      );
    }

    if (item.iconName === "calendar_today") {
      return (
        <Link to={item.redirectURL}>
          <span className={item.iconClass}>{item.iconName}</span>
          <br />
          <div>{item.name}</div>
        </Link>
      );
    }

    if (item.iconName === "shopping_bag") {
      return (
        <Link to={item.redirectURL} >
          <span className={item.iconClass}>{item.iconName}</span>
          <br />
          <div>{item.name}</div>
        </Link>
      );
    }

    return (
        <a href={item.redirectURL} target={item.linkTarget}>
        <span className={item.iconClass}>{item.iconName}</span>
        <br />
        <div>{item.name}</div>
      </a>
    );
  };

  const MenuItemLogInComponent = () => {
    return (
      <>
        <li className="menu-li sign-in-li">
          {checkLogin.isLoggedIn && userInfoData.result?.firstName && <div className="header-welcome hidden-xs hidden-sm hidden-md">Welcome, {savedUserInfoData?.firstName}</div>}
          <a id="aLogin" className="toggle-control-login" onClick={checkLogin.isLoggedIn ? undefined : toggleLogin} href={checkLogin.isLoggedIn ? "/member-dashboard" : "#"}>
            <span className="material-icons-outlined" style={{ color: "#0E8ACB", verticalAlign: "bottom" }}>
              perm_contact_calendar
            </span>

            {userInfoData.result ? (
              <></>
            ) : (
              <span id="signinbrtag">
                <br />
              </span>
            )}

            {!checkLogin.isLoggedIn && (
              <>
                <span id="spanLoggedOut">
                  <span className="sr-only">Open/Close </span>Sign In
                </span>
              </>
            )}
            {checkLogin.isLoggedIn && (
              <span id="spanWelcome">
                <span className="sr-only">Welcome</span> My RAPS
              </span>
            )}
          </a>
        </li>
      </>
    );
  };

  const MenuItemJoinLogoutRenewComponent = () => {
    return (
      <>
        <li className="menu-li join-li">
          {!checkLogin.isLoggedIn && (
            <Link id="lnkJoin" className="btn btn-default join-btn join btn-responsive" to="/join-raps">
              Join
            </Link>
          )}
          {checkLogin.isLoggedIn && userInfoData.result && userInfoData.result.expireDate !== "Not Get" && !userInfoData.result.isActiveMember && (
            <>
              <Link id="lnkRenew" className="btnLogout btn btn-default btn-lg join-btn" to="/renew-membership">
                Renew
              </Link>{" "}
            </>
          )}
          {checkLogin.isLoggedIn && (
            <Link id="lnkLogout" className="btnLogout btn btn-default btn-lg join-btn" onClick={logoutUser} to="#">
              Logout
            </Link>
          )}
        </li>
      </>
    );
  };

  return (
    <>
      <div id="header_new">
        <div className="container">
          <div className="header-top row">
            <div className="col-lg-2">
              <Nav.Link href={isNewsPages ? "/news-and-articles" : "/"} className="brand">
                <img src={isNewsPages ? rfLogo : rapsSiteLogo} className="log-img-1" alt={isNewsPages ? "Regulatory Focus Logo" : "Regulatory Affairs Professionals Society Logo"} style={isNewsPages ? {maxWidth: "112%"} : {} } />
              </Nav.Link>
            </div>
            <div className="col-lg-10 col-sm-12 col-xs-12">
              {/*menuItem()*/}
              <ul className="menu-ul">
                {props.menu &&
                  props.menu.map((menuItem) => {
                    return menuItem.data.map((item, index) => {
                      return (
                        <li className={item.listClass} key={index}>
                          {item.buttontype ? (
                            <Nav.Link href="#" className="btn btn-default join-btn join btn-responsive">
                              {item.name}
                            </Nav.Link>
                          ) : (<>{menuState(item)}</>)}
                        </li>
                      );
                    });
                  })}
                <MenuItemLogInComponent />
                <MenuItemJoinLogoutRenewComponent />
                {isNewsPages && (
                  <li className="menu-li homepage">
                    <a href="/">
                      <img alt="Regulatory Affairs Professionals Society Logo" src={rapslogoFullcolor} style={{ width: "51px", marginBottom: "20px" }} />
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderNav;
