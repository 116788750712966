import { apiErrorHandler } from "../../errors/api-error-handler";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MasterPageConfigurationService from "../../services/master-page-configuration.service";
import { MasterPageConfigurationInitialStateType, MasterPageConfigurationType } from "../../models/master-page-configuration.model";

const initialState: MasterPageConfigurationInitialStateType = {
  masterPageConfigurationData: [],
  masterPageConfigurationLoading: true,
  masterPageConfigurationError: undefined,
};

export const retrieveMasterPageConfiguration = createAsyncThunk("MasterPageConfiguration/retrieve", async (data, { rejectWithValue }) => {
  try {
    const response = await MasterPageConfigurationService.getAllMasterPageConfiguration();
    return [response.data];
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.status);
  }
});

const masterPageConfigurationSlice = createSlice({
  name: "masterPageConfiguration",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveMasterPageConfiguration.pending, (state) => {
        state.masterPageConfigurationLoading = true;
      })
      .addCase(retrieveMasterPageConfiguration.fulfilled, (state, action) => {
        if (action.payload) {
          state.masterPageConfigurationLoading = false;
          state.masterPageConfigurationData = action.payload as MasterPageConfigurationType[];
        }
      })
      .addCase(retrieveMasterPageConfiguration.rejected, (state, { payload }) => {
        state.masterPageConfigurationLoading = false;
        state.masterPageConfigurationError = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default masterPageConfigurationSlice.reducer;
