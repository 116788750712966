import http from "../../http-common"

// class SearchService {
//     getSearch(pageNumber: number = 1, searchType: string = 'recommended', relevance: string = 'relevance') {
        
//         return http.get(`/search?pageNumber=${pageNumber}&searchType=${searchType}&sortBy=${relevance}`)
//     }  
// }

class SearchService {
  getSearch(searchText: string, sector: string, pageNumber: number, searchType: string, relevance: string ) {
        
    return http.get(`/search?searchText=${searchText}&taxonomy=${sector}&pageNumber=${pageNumber}&searchType=${searchType}&sortBy=${relevance}`)
    }  
}

export default new SearchService();