import React, { ReactNode } from "react";
import { Navigate } from 'react-router-dom';

const SitePages = React.lazy(() => import('../pages/eucrm/eucrm-index'));
const PageNotFound = React.lazy(() => import("../pages/errors/page-not-found"));

export type route = {
    path: string;
    component: ReactNode;
    exact: boolean | undefined;
    name?: string;
};

const EUCRMConference: route[] = [
  { path: '/', exact: true, name: 'EUCRPS Home', component: <Navigate to={`/eucrps/home${window.location.search}`} replace /> },
  { path: '/*', exact: true, name: 'EUCRPS All', component: <SitePages /> },

  { path: '/*', exact: true, component: <PageNotFound /> },
]
export default EUCRMConference;
