import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { AllRedirectUrlsInitialStateType, AllRedirectUrlsResponse } from "../../../models/url-redirect.model";
import UrlRedirectService from "../../../services/url-redirect.service";

const initialState: AllRedirectUrlsInitialStateType = {
  data: {} as AllRedirectUrlsResponse,
  loading: false,
  error: undefined,
};

export const retrieveAllRedirectUrls = createAsyncThunk("allRedirectUrls/retrieve", async () => {
  const service = new UrlRedirectService();

  const response = await service.getAllRedirectUrls();
  return response.data;
});

export const cleanAllRedirectUrls = createAsyncThunk("allRedirectUrls/clean", async () => {
  return;
});

const allRedirectUrlsSlice = createSlice({
  name: "allRedirectUrls",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveAllRedirectUrls.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveAllRedirectUrls.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as AllRedirectUrlsResponse;
        }
      })
      .addCase(retrieveAllRedirectUrls.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      })
      .addCase(cleanAllRedirectUrls.fulfilled, (state) => {
        state.data = {} as AllRedirectUrlsResponse;
        state.loading = false;
        state.error = undefined;
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default allRedirectUrlsSlice.reducer;
