import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import {
    LatestDiscussionInitialStateType,
    LatestDiscussionResponse,
} from "../../../models/external/latest-discussion-model";
import LatestDiscussionService from "../../../services/external/external-api-call.service";

type latestDiscussionRequest = {
  url: string,
  hliamKey: string,
  hlPassword: string
}

const initialState: LatestDiscussionInitialStateType = {
  data: [],
  loading: true,
  error: undefined,
};

export const retrieveLatestDiscussion = createAsyncThunk(
  "latestDiscussion/retrieve",
  async (request: latestDiscussionRequest) => {
    const response = await LatestDiscussionService.callExternalApi(request);
    if (response !== undefined)
      return [...response.data];
  }
);

const latestDiscussionSlice = createSlice({
  name: "latestDiscussion",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveLatestDiscussion.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveLatestDiscussion.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as LatestDiscussionResponse[];
        }
      })
      .addCase(retrieveLatestDiscussion.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default latestDiscussionSlice.reducer;
