import React, { ReactNode } from "react";
import { Navigate } from 'react-router-dom';

const Pages = React.lazy(() => import("../pages/riconf/riconf-index"));
const PageNotFound = React.lazy(() => import("../pages/errors/page-not-found"));

export type route = {
  component: ReactNode;
  path: string;
  routes?: route[];
  [propName: string]: any;
};

const Routes: route[] = [
  { path: '/', exact: true, name: 'Regulatory Intelligence Conference Home', component: <Navigate to={`/riconf/home${window.location.search}`} replace /> },
  { path: '/*', exact: true, name: 'Regulatory Intelligence Conference All', component: <Pages /> },

  { path: '/*', exact: true, component: <PageNotFound /> },
]

export default Routes;