import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";

import { LocalNetworkInitialStateType, LocalNetworkResponseType } from "../../models/local-network.model";
import LocalNetworkService from "../../services/local-network.service";

export const retrieveLocalNetwork = createAsyncThunk("localNetwork/retrieve", async (folderPath: string) => {
  const response = await LocalNetworkService.getLocalNetwork(folderPath);
  return response.data;
});

const localNetworkSlice = createSlice({
  name: "localNetwork",
  initialState: {} as LocalNetworkInitialStateType,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveLocalNetwork.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveLocalNetwork.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.response = action.payload as LocalNetworkResponseType;
        }
      })
      .addCase(retrieveLocalNetwork.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default localNetworkSlice.reducer;