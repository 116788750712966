import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import {
  PreferencesInitialStateType,
  PreferencesResponse,
} from "../../models/preferences.model";
import PreferencesService from "../../services/preferences.service";

const initialResponse: PreferencesResponse = {
    statusCode: 0,
    statusMessage: "",
    result: []
}

const initialState: PreferencesInitialStateType = {
  data: initialResponse,
  loading: true,
  error: undefined,
};

export const retrievePreferences = createAsyncThunk(
  "preferences/retrieve",
  async () => {
    const response = await PreferencesService.getPreferencesList();
    return response.data;
  }
);

const preferencesSlice = createSlice({
  name: "preferences",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrievePreferences.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrievePreferences.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as PreferencesResponse;
        }
      })
      .addCase(retrievePreferences.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default preferencesSlice.reducer;
