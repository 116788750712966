import React, { ReactNode, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

interface Props {
    children: ReactNode
}

export default ({ children }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, getUser } = useAuth();

  let loginPath = `/login?ReturnUrl=${location.pathname}`;
  if (location.search) loginPath += `${location.search}`;

  useEffect(() => {
    if (!getUser()) {
      navigate(loginPath, { state: { from: location.pathname }, replace: true })
    }
  }, [user])
  
  return (<>
    {children}
  </>);
};
