import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { EventCategoryInitialStateType, EventCategoryResponse } from "../../../models/events/event-category.model";
import EventCategoryService from "../../../services/events/event-category.service";

const initialState: EventCategoryInitialStateType = {
  data: {} as EventCategoryResponse,
  loading: true,
  error: undefined,
};

export const retrieveEventCategory = createAsyncThunk("eventCategory/retrieve", async () => {
  const service = new EventCategoryService();

  const response = await service.getEventCategory();
  return response.data;
});

const eventCategorySlice = createSlice({
  name: "eventCategory",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveEventCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveEventCategory.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as EventCategoryResponse;
        }
      })
      .addCase(retrieveEventCategory.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default eventCategorySlice.reducer;
