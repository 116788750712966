import { TopArticlesForHomePageInitialStateType, TopArticlesForHomePageType } from './../../models/top-articles-for-home-page-model'
import { apiErrorHandler } from './../../errors/api-error-handler';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TopArticlesForHomePage from "../../services/top-articles-for-home-page-service"

const initialState: TopArticlesForHomePageInitialStateType = {
    TopArticlesForHomePageData: [],
    TopArticlesForHomePageLoading: true,  
    TopArticlesForHomePageError: undefined,
} ;

export const retrieveTopArticlesForHomePage = createAsyncThunk('topArticlesForHomePage/retrieve',
    async (data, { rejectWithValue }) => {
        try {
            const response = await TopArticlesForHomePage.getTopArticlesForHomePage(5);
         return [response.data]
         
        } catch (error: any) {
            if (!error.response) {
                throw error
              }
            return rejectWithValue(error.response.status)
            
        }
        
    }
)



const topArticlesForHomePage = createSlice({
    name: 'primaryMenu',
    initialState: initialState,
    reducers: {


    },
    extraReducers: (builder) => {
        builder
            .addCase(retrieveTopArticlesForHomePage.pending, (state) => {
                    state.TopArticlesForHomePageLoading = true;
            })
            .addCase(retrieveTopArticlesForHomePage.fulfilled, (state, action) => {
                if(action.payload){
                    state.TopArticlesForHomePageLoading = false;
                    state.TopArticlesForHomePageData = action.payload as TopArticlesForHomePageType[];
                }   
            })        
            .addCase(retrieveTopArticlesForHomePage.rejected, (state, {payload}) => {
                    state.TopArticlesForHomePageLoading = false;
                    state.TopArticlesForHomePageError = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default topArticlesForHomePage.reducer;
