import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import { FAQsInitialStateType, FAQsResponse } from "../../models/faq.model";
import FAQsService from "../../services/faq-service";

const initialState: FAQsInitialStateType = {
  data: {} as FAQsResponse,
  loading: true,
  error: undefined,
};

export const retrieveFAQs = createAsyncThunk("faqs/retrieve", async (urlPath: string) => {
  const service = new FAQsService();

  const response = await service.getFAQs(urlPath);
  return response.data;
});

const faqsSlice = createSlice({
  name: "faqs",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveFAQs.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveFAQs.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as FAQsResponse;
        }
      })
      .addCase(retrieveFAQs.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default faqsSlice.reducer;
