import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../redux/store";
import { useLocalStorage } from "./useLocalStorage";
import { retrieveAllRedirectUrls } from "../redux/slice/redirect-urls/all-redirect-urls.slice";

const useRawRedirectUrls = () => {
  const allRedirectUrls = useSelector((state: RootState) => state.allRedirectUrls);
  const { loading, data } = allRedirectUrls;
  const [redirectUrls, setRedirectUrls] = useLocalStorage("redirectUrls", null);
  const dispatch = useAppDispatch();

  const callRedirectListApi = () => {
    if (!loading && data.urlRedirectLists === undefined && redirectUrls === null) {
      dispatch(retrieveAllRedirectUrls()).then((data) => {
        let urlRedirectLists = {
          redirectList: data.payload.urlRedirectLists,
          expiry: new Date().getTime() + 10000,//360000,
        };
        setRedirectUrls(urlRedirectLists);
      });
    } else if (!loading && redirectUrls !== null) {
      if (new Date().getTime() > redirectUrls.expiry) {
        localStorage.removeItem("redirectUrls");
        dispatch(retrieveAllRedirectUrls()).then((data) => {
          let urlRedirectLists = {
            redirectList: data.payload.urlRedirectLists,
            expiry: new Date().getTime() + 10000,
          };
          setRedirectUrls(urlRedirectLists);
        });
      }
    }
  };

  return { redirectUrls, callRedirectListApi };
};

export default useRawRedirectUrls;
