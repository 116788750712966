import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import {
  TaxonomyInitialStateType,
  TaxonomyType,
} from "../../models/taxonomy-model";
import TaxonomyEventService from "../../services/taxonomy-event.service";

const initialState: TaxonomyInitialStateType = {
  data: [],
  loading: true,
  error: undefined,
};

export const retrieveTaxonomyEvent = createAsyncThunk(
  "taxonomyEvent/retrieve",
  async (eventPath: string) => {
    const response = await TaxonomyEventService.getTaxonomyEventList(eventPath);
    return [...response.data.result];
  }
);

const taxonomyEventSlice = createSlice({
  name: "taxonomyEvent",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveTaxonomyEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveTaxonomyEvent.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as TaxonomyType[];
        }
      })
      .addCase(retrieveTaxonomyEvent.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default taxonomyEventSlice.reducer;
