import { RapsEuropeTypes, RapsEuropeInitialStateType } from "../../models/raps-europe-model";
import { apiErrorHandler } from "../../errors/api-error-handler";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PageMenuService from "../../services/page-menu.service";

const initialState: RapsEuropeInitialStateType = {
  RapsEuropeData: [],
  PageMenuEurope2023BannerData: [],
  RapsEuropeLoading: false,
  RapsEuropeError: undefined,
  fromSSR: false,
  pageNotFound: false,
};

export const retrievePageMenu = createAsyncThunk("PageMenu/retrieve", async (path: string, { rejectWithValue }) => {
  try {
    const response = await PageMenuService.getPageMenu(path);
    return [response.data];
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.status);
  }
});

export const cleanPageMenu = createAsyncThunk("PageMenu/clean", async () => {
  return;
});


const pageMenuSlice = createSlice({
  name: "page-menu",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrievePageMenu.pending, (state) => {
        state.RapsEuropeLoading = true;
        state.pageNotFound = false;
      })
      .addCase(retrievePageMenu.fulfilled, (state, action) => {
        state.RapsEuropeLoading = false;
        
        if (action.payload[0]?.result?.length !== 0) {
          state.RapsEuropeData = action.payload as RapsEuropeTypes[];
        } else {
          state.RapsEuropeData = [];
          state.pageNotFound = true;
        }
      })
      .addCase(retrievePageMenu.rejected, (state, { payload }) => {
        state.RapsEuropeLoading = false;
        state.RapsEuropeError = apiErrorHandler(Number(payload));
      })
      .addCase(cleanPageMenu.fulfilled, (state) => {
        state.RapsEuropeData = [];
        state.PageMenuEurope2023BannerData = [];
        state.RapsEuropeLoading = false;
        state.RapsEuropeError = undefined;
      })
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default pageMenuSlice.reducer;
