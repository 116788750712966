
import http from "../../http-common";

class BlackFridayService {
    getBlackFriday(path: string, pageNumber: number) {
        return http.get(`/education/black-friday/tabs/${path}?pageNumber=${pageNumber}`);
    }
}

export default new BlackFridayService();
