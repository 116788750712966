import React, { ReactNode } from "react";
import { Navigate } from 'react-router-dom';

const Pages = React.lazy(() => import("../pages/eucomboprods/eu-combo-index"));
const PageNotFound = React.lazy(() => import("../pages/errors/page-not-found"));

export type route = {
  component: ReactNode;
  path: string;
  routes?: route[];
  [propName: string]: any;
};

const Routes: route[] = [
  { path: '/', exact: true, name: 'EUComboProducts Home', component: <Navigate to={`/eucomboprods/home${window.location.search}`} replace /> },
  { path: '/*', exact: true, name: 'EUComboProducts All', component: <Pages /> },

  { path: '/*', exact: true, component: <PageNotFound /> },
]

export default Routes;