import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import { SearchFilterOptionsInitialStateType, Taxonomy } from '../../../models/search-page/search-filter-options-model'
import SearchFilterOptionsService from '../../../services/search-page/search-filter-options-service';

const initialState: SearchFilterOptionsInitialStateType ={
    filterData: [],
    loading: true,
    error: undefined
}

export const retrieveSearchFilters = createAsyncThunk('searchFilter/retrieve',
    async() => {
        const response = await SearchFilterOptionsService.getSearchFilterOptions("sector")
        return [...response.data.result]
    }
)

export const retrieveSearchFiltersRegion = createAsyncThunk('searchFilterRegion/retrieve',
    async() => {
        const response = await SearchFilterOptionsService.getSearchFilterOptions("region")
        return [...response.data.result]
    }
)

export const retrieveSearchFiltersProfInt = createAsyncThunk('searchFilterProfInt/retrieve',
    async() => {
        const response = await SearchFilterOptionsService.getSearchFilterOptions("profinterest")
        return [...response.data.result]
    }
)

const searchFilterSlice = createSlice({
    name: 'searchFilter',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveSearchFilters.pending, (state) => {
                state.loading = true;
            })
            .addCase(retrieveSearchFilters.fulfilled, (state, action) => {
                if(action.payload){
                    state.loading = false;
                    state.filterData = action.payload as Taxonomy[];
                }
            })
            .addCase(retrieveSearchFilters.rejected, (state, {payload}) =>{
                state.loading = false;
                state.error = apiErrorHandler(Number(payload))
            })
    }
})

const searchFilterRegionSlice = createSlice({
    name: 'searchFilterRegion',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveSearchFiltersRegion.pending, (state) => {
                state.loading = true;
            })
            .addCase(retrieveSearchFiltersRegion.fulfilled, (state, action) => {
                if(action.payload){
                    state.loading = false;
                    state.filterData = action.payload as Taxonomy[];
                }
            })
            .addCase(retrieveSearchFiltersRegion.rejected, (state, {payload}) =>{
                state.loading = false;
                state.error = apiErrorHandler(Number(payload))
            })
    }
})

const searchFilterProfIntSlice = createSlice({
    name: 'searchFilterProfInt',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveSearchFiltersProfInt.pending, (state) => {
                state.loading = true;
            })
            .addCase(retrieveSearchFiltersProfInt.fulfilled, (state, action) => {
                if(action.payload){
                    state.loading = false;
                    state.filterData = action.payload as Taxonomy[];
                }
            })
            .addCase(retrieveSearchFiltersProfInt.rejected, (state, {payload}) =>{
                state.loading = false;
                state.error = apiErrorHandler(Number(payload))
            })
    }
})


export const selectAllEntities = (state: {entities: any}) => state.entities;
export default {
    sector: searchFilterSlice.reducer,
    region: searchFilterRegionSlice.reducer,
    profInt: searchFilterProfIntSlice.reducer
}
