import React, { ReactNode } from "react";
import { Navigate } from 'react-router-dom';

const Pages = React.lazy(() => import("../pages/eucomboprods/rpf-index"));
const PageNotFound = React.lazy(() => import("../pages/errors/page-not-found"));

export type route = {
    component: ReactNode;
    path: string;
    routes?: route[];
    [propName: string]: any;
};

const Routes: route[] = [
    { path: '/', exact: true, name: 'Reg Policy Forum Home', component: <Navigate to={`/regpolicyforum/home${window.location.search}`} replace /> },
    { path: '/*', exact: true, name: 'Reg Policy Forum All', component: <Pages /> },

    { path: '/*', exact: true, component: <PageNotFound /> },
]

export default Routes;