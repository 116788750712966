import { apiErrorHandler } from './../../errors/api-error-handler';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FooterMenuService from "../../services/footer-menu.service"
import { FooterMenuInitialStateType, FooterMenuType } from '../../models/footer-menu-model';

const initialState: FooterMenuInitialStateType = {
    FooterMenuData: [],
    FooterMenuLoading: true,  
    FooterMenuError: undefined,
} ;

export const retrieveFooterMenu = createAsyncThunk('FooterMenu/retrieve',
    async (data, { rejectWithValue }) => {
        try {
            const response = await FooterMenuService.getAllFooterMenu();
            return [response.data]
        
        } catch (error: any) {
            if (!error.response) {
                throw error
              }
            return rejectWithValue(error.response.status)
            
        }
        
    }
)

const footerMenuSlice = createSlice({
    name: 'FooterMenu',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(retrieveFooterMenu.pending, (state) => {
                    state.FooterMenuLoading = true;
            })
            .addCase(retrieveFooterMenu.fulfilled, (state, action) => {
                if(action.payload){
                    state.FooterMenuLoading = false;
                    state.FooterMenuData = action.payload as FooterMenuType[];
                }   
            })        
            .addCase(retrieveFooterMenu.rejected, (state, {payload}) => {
                    state.FooterMenuLoading = false;
                    state.FooterMenuError = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default footerMenuSlice.reducer;
