import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { retrieveSiteMap } from "../redux/slice/sitemap-slice";
import { RootState, useAppDispatch } from "../redux/store";
//import { js2xml } from 'xml-js';

const Sitemap = () => {

    const dispatch = useAppDispatch()
    const { SiteMapData } = useSelector((state: RootState) => state.SiteMap)

    useEffect(() => {
        dispatch(retrieveSiteMap())
    }, [dispatch])

    //const xmlData = js2xml(SiteMapData, {compact: true, spaces: 4});

  return <>
        <style>
            {`
                .loader-container {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100Vh;
                }
            `}
        </style>
        <Helmet>
        </Helmet>
        {/* {
            (SiteMapLoading) ? <LoadingComp /> : (
                <pre>{xmlData}</pre>
            )
        } */}
 
        </>
}

export default Sitemap
