import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import {
    LoginInitialStateType, LogoutInitialStateType, KenticoLoginInitialStateType, KenticoLogoutInitialStateType,
    LoginType, LogoutType, KenticoLoginType, KenticoLogoutType
} from "../../../models/authentication-model";
import AuthenticationService from "../../../services/sso/authentication.service";
import axios from "axios";

type LoginRequest = {
    username: string,
    password: string,
    staySignedIn: boolean
}

const initialLoginState: LoginInitialStateType = {
  data: [],
  loading: true,
  error: undefined,
};

const initialLogoutState: LogoutInitialStateType = {
  data: [],
  loading: true,
  error: undefined,
};

const initialKenticoLoginState: KenticoLoginInitialStateType = {
  data: [],
  loading: true,
  error: undefined,
};
  
const initialKenticoLogoutState: KenticoLogoutInitialStateType = {
  data: [],
  loading: true,
  error: undefined,
};

export const ssoLogin = createAsyncThunk(
  "ssoLogin/post",
  async (request: LoginRequest) => {
    const response = await AuthenticationService.LoginService.login(request.username, request.password);
    return [...response.data.result];
  }
);

const ssoLoginSlice = createSlice({
  name: "ssoLogin",
  initialState: initialLoginState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ssoLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(ssoLogin.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as LoginType[];
        }
      })
      .addCase(ssoLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(action.payload));
      });
  },
});

export const ssoLogout = createAsyncThunk(
  "ssoLogout/post",
  async () => {
    const response = await AuthenticationService.LogoutService.logout()
    return [...response.data.result];
  }
);

const ssoLogoutSlice = createSlice({
    name: "ssoLogout",
    initialState: initialLogoutState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(ssoLogout.pending, (state) => {
          state.loading = true;
        })
        .addCase(ssoLogout.fulfilled, (state, action) => {
          if (action.payload) {
            state.loading = false;
            state.data = action.payload as LogoutType[];
          }
        })
        .addCase(ssoLogout.rejected, (state, action) => {
          state.loading = false;
          state.error = apiErrorHandler(Number(action.payload));
        });
    },
  });

  export const login = createAsyncThunk(
    "login/post",
    async (request: LoginRequest) => {
      const response = await AuthenticationService.KenticoLoginService.login(request.username, request.password, request.staySignedIn);
      return [...response.data.result];
    }
  );
  
  const loginSlice = createSlice({
    name: "login",
    initialState: initialKenticoLoginState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(login.pending, (state) => {
          state.loading = true;
        })
        .addCase(login.fulfilled, (state, action) => {
          if (action.payload) {
            state.loading = false;
            state.data = action.payload as KenticoLoginType[];
          }
        })
        .addCase(login.rejected, (state, action) => {
          state.loading = false;
          state.error = apiErrorHandler(Number(action.payload));
        });
    },
  });

  export const logout = createAsyncThunk(
    "logout/post",
    async (accessToken: string) => {
      const response = await AuthenticationService.KenticoLogoutService.logout(accessToken)
      if (response?.data?.result[0]?.ssoLogoutUrl) logoutUserFromMyRaps(response.data.result[0].ssoLogoutUrl);
      return [...response.data.result];
    }
  );

  const logoutUserFromMyRaps = async (url: string) => {
    try {
      const instance = axios.create({
        baseURL: url,
        headers: {
          "Content-type": "application/json",
          "Cache-Control": "no-cache",
        },
      });
      await instance.get("").catch((err) => {});
    } catch (err) {}
  };
  
  const logoutSlice = createSlice({
      name: "logout",
      initialState: initialKenticoLogoutState,
      reducers: {},
      extraReducers: (builder) => {
        builder
          .addCase(logout.pending, (state) => {
            state.loading = true;
          })
          .addCase(logout.fulfilled, (state, action) => {
            if (action.payload) {
              state.loading = false;
              state.data = action.payload as KenticoLogoutType[];
            }
          })
          .addCase(logout.rejected, (state, action) => {
            state.loading = false;
            state.error = apiErrorHandler(Number(action.payload));
          });
      },
    });

  const isLoggedInSlice = createSlice({
    name: "isLoggedIn",
    initialState: { 
      checkLogin: { isLoggedIn: false },
      userData: {} as KenticoLoginType | null,
    },
    reducers: {
      setLoggedInTrue(state) {
        state.checkLogin.isLoggedIn = true;
      },
      setLoggedInFalse(state) {
        state.checkLogin.isLoggedIn = false;
      },
      setUserData(state, { payload }) {
        state.userData = payload as KenticoLoginType | null;
      },
    }
  });


export const selectAllEntities = (state: { entities: any }) => state.entities;
export const { setLoggedInTrue, setLoggedInFalse, setUserData } = isLoggedInSlice.actions;
export default {
    ssoLogin: ssoLoginSlice.reducer,
    ssoLogout: ssoLogoutSlice.reducer,
    login: loginSlice.reducer,
    logout: logoutSlice.reducer,
    isLoggedIn: isLoggedInSlice.reducer,
}
