import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import {
  TaxonomyInitialStateType,
  TaxonomyType,
} from "../../models/taxonomy-model";
import MyContentService from "../../services/my-content.service";

const initialState: TaxonomyInitialStateType = {
  data: [],
  loading: true,
  error: undefined,
};

export const retrieveUserTaxonomy = createAsyncThunk("taxonomyUser/retrieve", async (userId: string) => {
  const service = new MyContentService();
  const response = await service.getUserTaxonomy(userId);
    return [...response?.data?.result];
  }
);

const taxonomyUserSlice = createSlice({
  name: "taxonomyUser",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveUserTaxonomy.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveUserTaxonomy.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as TaxonomyType[];
        }
      })
      .addCase(retrieveUserTaxonomy.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default taxonomyUserSlice.reducer;
