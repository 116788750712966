import http from "../../http-common";

class CatalogPathService {
  getCatalogPath(path: string) {
    const queryParams = new URLSearchParams(window.location.search);
    const isPreview = queryParams.get("isPreview") && queryParams.get("wg");
    let addQuery = isPreview ? `&isPreview=true&wg=${queryParams.get("wg")}` : "";

    return http.get(`/education/catalog?path=${path}${addQuery}`);
  }
}

export default new CatalogPathService();
