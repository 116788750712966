import http from "../http-common"

class CookieLawServices {
    getAllCookieLaw() {
        return http.get(`/cookie-law`)
    }
    postCookieLaw(cookieLavel: number) {
        return http.post(`/cookie-law/newlevel?newLevel=${cookieLavel}`)
    }
}


export default new CookieLawServices();