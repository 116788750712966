import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../../errors/api-error-handler";
import {  RFQuarterTypes, RFQuarterInitialStateType } from "../../../../models/news-articles/regulatory-focus/rf-quarterly-model";
import RegulatoryFocusRFQuarterlyServices from "../../../../services/news-articles/regulatory-focus/rf-quarterly.service";

const initialState: RFQuarterInitialStateType = {
    RFQuarterData: [],
    RFQuarterLoading: true,
    RFQuarterError: undefined,
};


  
export const retrieveRFQuarterly = createAsyncThunk(
    "RFQuarterly/retrieve",
    async (__data,  { rejectWithValue }) => {
      try {
          const response = await RegulatoryFocusRFQuarterlyServices.getAllRegulatoryFocusRFQuarterlyFocus();
          return [response.data];
      }catch (error: any) {
          if (!error.response) {
              throw error
            }
          return rejectWithValue(error.response.status) 
      }   
  })
    

const RFQuarterlySlice = createSlice({
  name: "RFQuarterlySlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveRFQuarterly.pending, (state) => {
        state.RFQuarterLoading = true;
      })
      .addCase(retrieveRFQuarterly.fulfilled, (state, action) => {
        if (action.payload) {
          state.RFQuarterLoading = false;
          state.RFQuarterData = action.payload as RFQuarterTypes[];
        }
      })
      .addCase(retrieveRFQuarterly.rejected, (state, { payload }) => {
        state.RFQuarterLoading = false;
        state.RFQuarterError = apiErrorHandler(Number(payload));
      })
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default RFQuarterlySlice.reducer;
