import { apiErrorHandler } from '../../errors/api-error-handler';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GetBooksAndBestsellersService from "../../services/getbooks-and-bestsellers.service"
import { GetBooksAndBestsellersInitialStateType, GetBooksAndBestsellersType } from '../../models/getbooks-and-bestsellers-model';

const initialState: GetBooksAndBestsellersInitialStateType = {
    BestsellersData: [],
    AllBooksInfoData: [],
    GetBooksAndBestsellersLoading: true,  
    GetBooksAndBestsellersError: undefined,
} ;

export const retrieveAllBooksInfo = createAsyncThunk('retrieveAllBooksInfo/retrieve',
    async (path: string, { rejectWithValue }) => {
        try {
            const response = await GetBooksAndBestsellersService.getAllGetBooksAndBestsellers(path)
            return [response.data]
        
        } catch (error: any) {
            if (!error.response) {
                throw error
              }
            return rejectWithValue(error.response.status)
            
        }
        
    }
)

const AllBooksInfoSlice = createSlice({
    name: 'AllBooksInfoSlice',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(retrieveAllBooksInfo.pending, (state) => {
                    state.GetBooksAndBestsellersLoading = true;
            })
            .addCase(retrieveAllBooksInfo.fulfilled, (state, action) => {
                if(action.payload){
                    state.GetBooksAndBestsellersLoading = false;
                    state.AllBooksInfoData = action.payload as GetBooksAndBestsellersType[];
                }   
            })        
            .addCase(retrieveAllBooksInfo.rejected, (state, {payload}) => {
                    state.GetBooksAndBestsellersLoading = false;
                    state.GetBooksAndBestsellersError = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default AllBooksInfoSlice.reducer;
