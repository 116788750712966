import http from "../../http-common";

class EducationPagesService {
  getEducationPages(path: string) {
    const queryParams = new URLSearchParams(window.location.search);
    const isPreview = queryParams.get("isPreview") && queryParams.get("wg");
    let addQuery = isPreview ? `&isPreview=true&wg=${queryParams.get("wg")}` : "";

    return http.get(`education?path=${path}${addQuery}`);
  }
}

export default new EducationPagesService();
