import http from "../../http-common"
import { pageSizeType, sortByType } from "../../redux/slice/news-articles/news-articles-search-slice";

class NewsArticlesSearchCategoryServices {
    getAllNewsArticlesCategory() {
        return http.get('news-articles-search/category')
    }
}


export default new NewsArticlesSearchCategoryServices();