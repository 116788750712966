import http from "../http-common";

class MyContentService {
  getNews(category: string) {
    let url = `/my-content/news?category=${category}`;

    return http.get(url);
  }

  getBooks(category: string) {
    let url = `/my-content/books?category=${category}`;

    return http.get(url);
  }

  getEvents(category: string) {
    let url = `/my-content/events?category=${category}`;

    return http.get(url);
  }

  getLearning(category: string) {
    let url = `/my-content/learning?category=${category}`;

    return http.get(url);
  }

  getTaxonomies() {
    let url = `/my-content/taxonomies`;

    return http.get(url);
  }

  getUserTaxonomy(userId: string) {
    let url = `/my-content/user?userId=${userId}`;

    return http.get(url);
  }
}

export default MyContentService;
