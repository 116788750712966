import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { RacMenuInitialStateType, RacMenuResponse } from "../../../models/rac-menu.model";
import RacMenuService from "../../../services/rac-menu.services";

const initialResponse: RacMenuResponse = {
  statusCode: 0,
  statusMessage: "",
  racMenuResults: [],
};

const initialState: RacMenuInitialStateType = {
  data: initialResponse,
  loading: true,
  error: undefined,
};

export const retrieveRacMenu = createAsyncThunk("racMenu/retrieve", async () => {
  const service = new RacMenuService();

  const response = await service.getRacMenu();
  return response.data;
});

const racMenuSlice = createSlice({
  name: "racMenu",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveRacMenu.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveRacMenu.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as RacMenuResponse;
        }
      })
      .addCase(retrieveRacMenu.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default racMenuSlice.reducer;
