import http from "../http-common";

class LatestArticlesService {
  getLatestArticles(numberOfArticles: number) {
    return http.get(
      "/articles/top/" + numberOfArticles
    );
  }
}

export default new LatestArticlesService();
