import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import { HeroJoinInitialStateType, HeroJoinResponse } from "../../models/hero-join.model";
import HeroJoinService from "../../services/hero-join.service";

const initialState: HeroJoinInitialStateType = {
  data: {} as HeroJoinResponse,
  loading: true,
  error: undefined,
};

const thunkCall = async (path: string) => {
  const service = new HeroJoinService();

  const response = await service.getHeroJoin(path);
  return response.data;
};

export const retrieveHeroJoin = createAsyncThunk("heroJoin/retrieve", thunkCall);
export const retrieveHeroJoin2 = createAsyncThunk("heroJoin2/retrieve", thunkCall);

const pendingState = (state: any) => {
  state.loading = true;
};

const fulfilledState = (state: any, action: any) => {
  if (action.payload) {
    state.loading = false;
    state.data = action.payload as HeroJoinResponse;
  }
};

const rejectedState = (state: any, action: any) => {
  state.loading = false;
  state.error = apiErrorHandler(Number(action.payload));
};

const heroJoinSlice = createSlice({
  name: "heroJoin",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveHeroJoin.pending, pendingState)
      .addCase(retrieveHeroJoin.fulfilled, fulfilledState)
      .addCase(retrieveHeroJoin.rejected, rejectedState);
  },
});

const heroJoinSlice2 = createSlice({
  name: "heroJoin2",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveHeroJoin2.pending, pendingState)
      .addCase(retrieveHeroJoin2.fulfilled, fulfilledState)
      .addCase(retrieveHeroJoin2.rejected, rejectedState);
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export const HeroJoinReducer = heroJoinSlice.reducer;
export const HeroJoin2Reducer = heroJoinSlice2.reducer;
