import { useContext, useEffect, useRef } from "react";
import Collapse from "react-bootstrap/esm/Collapse";
import { Link, useNavigate } from "react-router-dom";
import { MenuCtx } from "./menu-context";

const MainSearch = () => {
    const navigate = useNavigate();
    const MneuCtxV = useContext(MenuCtx);
    const [isActiveSearch, setIsActiveSearch] = MneuCtxV.search
    const headerSearch = useRef<HTMLInputElement>(null)
    const siteSearch = useRef<HTMLDivElement>(null);
    
      const handleClickOutside = (event: MouseEvent) => {
        if (siteSearch?.current && !siteSearch?.current?.contains(event.target as Node)) {
            setIsActiveSearch(false)
        }
      };

const closeSearch = () => {
    if(MneuCtxV){
        setIsActiveSearch(false)
    }
}

const handleHeaderSearch = () => {
        setIsActiveSearch(false)
        window.location.href = `/search?searchtext=${headerSearch.current?.value}`
}
const searchKeyDownButtonHandler = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
        window.location.href = `/search?searchtext=${headerSearch.current?.value}`
      setIsActiveSearch(false)
    }
  };


useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

    return (<Collapse in={isActiveSearch} >	
        <div id="siteSearch" ref={siteSearch} className={`input-group-site-search collapsible-group`} aria-labelledby="data-toggle-1">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 ">
                        <Link className="search-close" to="#" onClick={closeSearch}>
                          <i className="fa-solid fa-xmark"></i><span className="sr-only">Close Search</span>
                        </Link>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-sm-3 col-md-2">
                            <label htmlFor="searchInput" id="search" className="control-label"><i className="fa-solid fa-magnifying-glass"></i> Search:</label>
                        </div>
                        <div className="col-sm-7 col-md-9">
                                <input name="search" type="text" maxLength={1000} id="searchInput" ref={headerSearch} className="form-control WatermarkText" placeholder="Search..." onKeyDown={searchKeyDownButtonHandler} />
                        </div>
                        <div className="col-sm-2 col-md-1">
                            <input type="submit" name="p$lt$ctl01$Header$ctlSearchBox$btnSearch" value="GO" id="p_lt_ctl01_Header_ctlSearchBox_btnSearch" onClick={() => handleHeaderSearch()} className="btn btn-default" />
                            
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 text-right">
                        <div id="p_lt_ctl01_Header_ctlSearchBox_pnlPredictiveResultsHolder" className="predictiveSearchHolder">
                </div>
                    </div>
                </div>
            </div>
        </div>
    </Collapse>
    ) 
}
export default MainSearch;