import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import { RightSideTrailType, RightSideTrailInitialStateType } from "../../../models/right-side-trail-model";
import ProductRightSideBooksService from '../../../services/right-side/product-right-side-books.service';

const initialState: RightSideTrailInitialStateType = {
    rightSideFeaturedBooksData: [],
    rightSideFeaturedLearningData: [],
    rightSideTrendingNewsData: [],
    rightSidePopularEventsData: [],
    Loading: true,
    Error: undefined,
};


export const retrieveProductRightSideBooks = createAsyncThunk('ProductRightSideBooks/retrieve',
    async (productPath: string, { rejectWithValue }) => {
        try {

            const response = await ProductRightSideBooksService.getProductRightSideBooksService(productPath)
            return [response.data]

        } catch (error: any) {
            if (!error.response) {
                throw error
            }
            return rejectWithValue(error.response.status)
        }
    }
)

const ProductRightSideBooksSlice = createSlice({
    name: 'ProductRightSideBooks',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveProductRightSideBooks.pending, (state) => {
                state.Loading = true;
            })
            .addCase(retrieveProductRightSideBooks.fulfilled, (state, action) => {
                if (action.payload) {
                    state.Loading = false;
                    state.rightSideFeaturedBooksData = action.payload as RightSideTrailType[];
                }
            })
            .addCase(retrieveProductRightSideBooks.rejected, (state, { payload }) => {
                state.Loading = false;
                state.Error = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default ProductRightSideBooksSlice.reducer;
