import React, { useContext, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MenuCtx } from "./menu-context";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ssoLogin, login, setLoggedInTrue, setLoggedInFalse } from "../../redux/slice/sso/authentication-slice";
import { RootState, useAppDispatch } from "../../redux/store";
import Collapse from "react-bootstrap/esm/Collapse";

import useAuth from "../../hooks/useAuth";

interface FormElements extends HTMLFormControlsCollection {
  loginUsername: HTMLInputElement,
  loginPassword: HTMLInputElement
}

interface LoginFormElement extends HTMLFormElement {
  readonly elements: FormElements
}

interface LoginRequest {
  username: string,
  password: string,
  staySignedIn: boolean
}

const LoginComponent = () => {
  const { loginUser } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [attemptedLoggedIn, setAttemptedLoggedIn] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const siteLogin = useRef<HTMLDivElement>(null);
  
  const MenuCtxV = useContext(MenuCtx);
  const [isActiveLogin, setIsActiveLogin] = MenuCtxV.login;
  const { checkLogin } = useSelector(
    (state: RootState) => state.loggedIn
  );
  const loginData = useSelector((state: RootState) => state.login.data);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClickOutside = (event: MouseEvent) => {
    if (siteLogin?.current && !siteLogin?.current?.contains(event.target as Node)) {
      setIsActiveLogin(false)
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!checkLogin.isLoggedIn && attemptedLoggedIn){
      let request: LoginRequest = {
        username: username,
        password: password,
        staySignedIn: false
      }
      dispatch(login(request));
      setShowErrorMessage(false);
    }
  }, [attemptedLoggedIn]);

  useEffect(() => {
    if (attemptedLoggedIn && loginData.length > 0){
      loginUser(loginData[0]);
      if (window.runGlobalScripts) {
        window.runGlobalScripts();
      }
    }
      
    else if (attemptedLoggedIn) {
      setAttemptedLoggedIn(false);
      setShowErrorMessage(true);
    }
  }, [loginData]);

  useEffect(() => {
    if (attemptedLoggedIn && checkLogin.isLoggedIn){
      setAttemptedLoggedIn(false);
      closeLogin();
      navigate("/member-dashboard")
    }
  }, [attemptedLoggedIn, checkLogin]);


  const closeLogin = () => {
    if(MenuCtxV) {
      setShowErrorMessage(false);
      setIsActiveLogin(false);
    }
  }

  function handleSubmit(event: React.FormEvent<LoginFormElement>) {
    event.preventDefault()
    setUsername(event.currentTarget.elements.loginUsername.value);
    setPassword(event.currentTarget.elements.loginPassword.value);
    setAttemptedLoggedIn(true);
  }


    return ( <Collapse in={isActiveLogin}>
      <div id="siteLogin" ref={siteLogin} className={`input-group-site-login collapsible-group ${isActiveLogin ? 'd-block': ''}`} aria-labelledby="p_lt_ctl01_Header_aLogin">
        <div className="container">
            <div className="row">
              <div className="col-xs-12">
                  <Link className="login-search-close" to="#" onClick={closeLogin}>
                    <i className="fa-solid fa-xmark"></i><span className="sr-only">Close Login</span>
                  </Link>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="row">
                  {/*<div className="col-md-12 text-center">
                    <p>Welcome to our new website! If this is the first time you are logging in on the new site, you will need to <Link to="https://my.raps.org/account/login.aspx?forgot=yes&amp;RedirectUrl=https%3a%2f%2fwww.raps.org%2f&amp;reload=timezone">reset your password</Link>. Please contact us at <Link to="mailto:raps@raps.org">raps@raps.org</Link> if you need assistance.</p>
                  </div>*/}
                  <div className="col-sm-2">
                    <label className="control-label">
                    <i className="fa-sharp fa-light fa-user"></i> Login
                    </label>
                  </div>

                  
                    <div className="col-sm-4 col-md-3">
                      <label className="sr-only" htmlFor="loginUsername">E-mail address</label>
                      <input name="loginUsername" id="loginUsername" type="text" className="form-control" placeholder="E-mail address"  autoComplete="on" />
                      {showErrorMessage && 
                        (<span className="error" id="spanError">E-mail address &amp; Password Combo is invalid</span>)
                      }
                    </div>
                    <div className="col-sm-4 col-md-3">
                      <label className="sr-only" htmlFor="loginPassword">Password</label>
                      <input name="loginPassword" type="password" id="loginPassword" className="form-control" placeholder="Password" />
                    </div>
                    <div className="col-sm-2">
                      <input type="submit" value="Login" className="loginBtn btn btn-default"  />
                    </div>
                 

                  <div className="col-sm-12 col-md-2">
                    <ul className="list-unstyled login-help">
                        <li>
                          <a href="https://my.raps.org/account/login.aspx?forgot=yes&amp;RedirectUrl=https%3a%2f%2fwww.raps.org%2f?reload=timezone" id="Header_ForgotPassword">
                            Forgot Password?
                          </a>
                        </li>
                        <li>
                          <a href="https://my.raps.org/account/login.aspx?signup=yes&amp;RedirectUrl=https%3a%2f%2fwww.raps.org%2f" id="Header_CreateAccount">
                            Create an Account
                          </a>
                        </li>
                    </ul>
                  </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      </Collapse>
    )
}

export default LoginComponent;