import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { ImpexiumSubscriptionsUpdateInitialStateType, ImpexiumSubscriptionsUpdateResponse } from "../../../models/impexium/impexium-subscriptions-update.model";
import ImpexiumSubscriptionsUpdateService from "../../../services/impexium/impexium-subscriptions-update.service";

interface SubscriptionUpdate {
  userId: string;
  subscriptionField: SubscriptionField[];
}

interface SubscriptionField {
  name: string;
  value: string;
}

const initialState: ImpexiumSubscriptionsUpdateInitialStateType = {
  data: {} as ImpexiumSubscriptionsUpdateResponse,
  loading: false,
  error: undefined,
};

export const postImpexiumSubscriptionsUpdate = createAsyncThunk("impexiumSubscriptionsUpdate/post", async (request: SubscriptionUpdate) => {
  const service = new ImpexiumSubscriptionsUpdateService();

  const response = await service.postPreferenceSubscriptions(request.userId, request.subscriptionField);
  return response.data;
});

export const cleanImpexiumSubscriptionsUpdate = createAsyncThunk("impexiumSubscriptionsUpdate/clean", async () => {
  return;
});

const impexiumSubscriptionsUpdateSlice = createSlice({
  name: "impexiumSubscriptionsUpdate",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postImpexiumSubscriptionsUpdate.pending, (state) => {
        state.loading = true;
      })
      .addCase(postImpexiumSubscriptionsUpdate.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as ImpexiumSubscriptionsUpdateResponse;
        }
      })
      .addCase(postImpexiumSubscriptionsUpdate.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      }).addCase(cleanImpexiumSubscriptionsUpdate.fulfilled, (state) => {
        state.data = {} as ImpexiumSubscriptionsUpdateResponse;
        state.loading = false;
        state.error = undefined;
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default impexiumSubscriptionsUpdateSlice.reducer;
