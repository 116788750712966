import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import {
  VolunteerMembeshipInitialStateType, VolunteerMembeshipResponse,
} from "../../models/external/volunteer-membership-model";
import VolunteerRegulatoryExchangeService from "../../services/volunteer-membership.service";

const initialState: VolunteerMembeshipInitialStateType = {
  data: [],
  loading: true,
  error: undefined,
};

export const retrieveVolunteerRegulatoryExchange = createAsyncThunk(
  "volunteerRegulatoryExchange/retrieve",
  async () => {
    const response = await VolunteerRegulatoryExchangeService.getVolunteerRegulatoryExchange();
    return [...response.data.regulatoryVolunteerData];
  }
);

const volunteerRegulatoryExchangeSlice = createSlice({
  name: "volunteerRegulatoryExchange",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveVolunteerRegulatoryExchange.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveVolunteerRegulatoryExchange.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as VolunteerMembeshipResponse[];
        }
      })
      .addCase(retrieveVolunteerRegulatoryExchange.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default volunteerRegulatoryExchangeSlice.reducer;
