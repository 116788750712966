import React, { ReactNode } from "react";
import { Navigate } from 'react-router-dom';

const Certifications = React.lazy(() => import("../pages/certifications"));
const PageNotFound = React.lazy(() => import("../pages/errors/page-not-found"));

export type route = {
    component: ReactNode;
    exact: boolean | undefined;
    name?: string;
    path: string;
    routes?: route[];
};

const CertificationsRoutes: route[] = [
  { path: '/', exact: true, name: 'Certification Home', component: <Navigate to={`/certifications/home${window.location.search}`} replace /> },
  { path: '/*', exact: true, name: "Certifications Catch All", component: <Certifications /> },

  //alternate urls
  //end of alternate urls

  { path: '/*', exact: true, component: <PageNotFound /> },
]
export default CertificationsRoutes;
