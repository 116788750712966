import React, { createContext, useEffect, useState } from "react"


export type MenuCtxType= {

    menu: [
        isActiveMenu: boolean,
        setIsActiveMenu: (value: boolean) => void
    ],
    login: [
        isActiveLogin: boolean,
        setIsActiveLogin: (value: boolean) => void
    ],
    search: [
        isActiveSearch: boolean,
        setIsActiveSearch: (value: boolean) => void
    ]
    
}

type MenuCtxProviderProps = {
    children: React.ReactNode
}

export const MenuCtx = createContext<MenuCtxType>({} as MenuCtxType)


export const CtxProvider = ({children}: MenuCtxProviderProps) => {

    const [isActiveMenu, setIsActiveMenu] = useState<boolean>(false);
    const [isActiveLogin, setIsActiveLogin] = useState<boolean>(false);
    const [isActiveSearch, setIsActiveSearch] = useState<boolean>(false);

  
    const handleEscKey = (event: KeyboardEvent) => {
        if (event.keyCode === 27) { // 27 is the keycode for the "Esc" key
            setIsActiveSearch(false)
            setIsActiveLogin(false)
        }
      };

      useEffect(() => {
        document.addEventListener('keydown', handleEscKey);
    
        return () => {
          document.removeEventListener('keydown', handleEscKey);
        };
      }, []);

    return <MenuCtx.Provider value={{menu: [isActiveMenu, setIsActiveMenu], login: [isActiveLogin, setIsActiveLogin], search: [isActiveSearch, setIsActiveSearch]}}>
                {children}
            </MenuCtx.Provider>

}


