import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import { WhoWeArePressReleasesInitialStateType, WhoWeArePressReleasesTypes } from '../../../models/who-we-are/press-releases-model'
import PressReleasesService from '../../../services/who-we-are/press-releases.services';

const initialState: WhoWeArePressReleasesInitialStateType ={
    WhoWeArePressReleasesData: [],
    WhoWeArePressReleasesLoading: true,
    WhoWeArePressReleasesError: undefined
}

export const retrieveWhoToArePressReleases = createAsyncThunk('searchWhoToArePressReleases/retrieve',
    async(data, { rejectWithValue }) => {
        try {
            const response = await PressReleasesService.getAllPressReleases()
            return [response.data]
        
        } catch (error: any) {
            if (!error.response) {
                throw error
              }
            return rejectWithValue(error.response.status)
            
        }
    }
)

const WhoToArePressReleasesSlice = createSlice({
    name: 'WhoToAre-PressReleases',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveWhoToArePressReleases.pending, (state) => {
                state.WhoWeArePressReleasesLoading = true;
            })
            .addCase(retrieveWhoToArePressReleases.fulfilled, (state, action) => {
                if(action.payload){
                    state.WhoWeArePressReleasesLoading = false;
                    state.WhoWeArePressReleasesData = action.payload as WhoWeArePressReleasesTypes[];
                }
            })
            .addCase(retrieveWhoToArePressReleases.rejected, (state, {payload}) =>{
                state.WhoWeArePressReleasesLoading = false;
                state.WhoWeArePressReleasesError = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: {entities: any;}) => state.entities;
export default WhoToArePressReleasesSlice.reducer;