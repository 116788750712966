import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import {
  LatestCoursesInitialStateType,
  LatestCoursesType,
} from "../../models/latest-courses-model";
import LatestCoursesService from "../../services/latest-courses.service";

const initialState: LatestCoursesInitialStateType = {
  featuredLearningData: [],
  loading: true,
  error: undefined,
};

export const retrieveLatestCourses = createAsyncThunk(
  "latestCourses/retrieve",
  async (numberOfProducts: number) => {
    const response = await LatestCoursesService.getLatestCourses(
      numberOfProducts
    );
    return [...response.data.result];
  }
);

const latestCoursesSlice = createSlice({
  name: "latestCourses",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveLatestCourses.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveLatestCourses.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.featuredLearningData = action.payload as LatestCoursesType[];
        }
      })
      .addCase(retrieveLatestCourses.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default latestCoursesSlice.reducer;
