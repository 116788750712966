import { useEffect } from "react";

export const useDynamicScripts = (props: { htmlScriptString: string, divId?: string }) => {
  const { htmlScriptString } = props;
  
  useEffect(() => {
    if (!htmlScriptString) return;

    if (props.divId) {
      let oldDiv = document.querySelector("#" + props.divId);
      if (oldDiv) document.body.removeChild(oldDiv);
    }

    const body = document.querySelector("body");
    const script = document.createRange().createContextualFragment(htmlScriptString);
    const div = document.createElement("div");
    div.setAttribute("class", "dynamicScripts");
    if (props.divId) div.setAttribute("id", props.divId);
    div.appendChild(script);
    body!.appendChild(div);

    return () => {
      document.body.removeChild(div)
    };
  }, [htmlScriptString]);
};
