import { apiErrorHandler } from "../../errors/api-error-handler";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ArticleDetailsService from "../../services/article-details.service";
import { ArticleDetaislInitialStateType, ArticleDetailsType } from "../../models/article-detaisls-model";

const initialState: ArticleDetaislInitialStateType = {
  ArticleDetailsData: [],
  ArticleDetaislLoading: false,
  ArticleDetaislError: undefined,
};

export const retrieveArticleDetails = createAsyncThunk("retrieveArticleDetails/retrieve", async (pathName: string, { rejectWithValue }) => {
  try {
    const response = await ArticleDetailsService.getAllarticleDetaisl(pathName);
    return [response.data];
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.status);
  }
});

export const cleanArticleDetails = createAsyncThunk("cleanArticleDetails/clean", async () => {
  return;
});

const ArticleDetailsSlice = createSlice({
  name: "ArticleDetailsSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveArticleDetails.pending, (state) => {
        state.ArticleDetaislLoading = true;
      })
      .addCase(retrieveArticleDetails.fulfilled, (state, action) => {
        if (action.payload) {
          state.ArticleDetaislLoading = false;
          state.ArticleDetailsData = action.payload as ArticleDetailsType[];
        }
      })
      .addCase(retrieveArticleDetails.rejected, (state, { payload }) => {
        state.ArticleDetaislLoading = false;
        state.ArticleDetaislError = apiErrorHandler(Number(payload));
      })
      .addCase(cleanArticleDetails.fulfilled, (state) => {
        state.ArticleDetailsData = [];
        state.ArticleDetaislLoading = false;
        state.ArticleDetaislError = undefined;
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default ArticleDetailsSlice.reducer;
