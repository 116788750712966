import axios, { AxiosRequestConfig, AxiosHeaders } from "axios";

export const baseURL = "/api";

const instance = axios.create({
  
  baseURL: `${baseURL}`,
  headers: {
      "Content-type": "application/json",
      "Cache-Control": "no-cache"
  }
});

interface KenticoLoginType {
  accessToken: string,
  refreshToken: {
    userName: string,
    tokenString: string,
    expireAt: string
  },
  ssoToken: string;
};

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  const user = JSON.parse(localStorage.getItem('user') || "") as KenticoLoginType;
  if (user != null)
    (config.headers as AxiosHeaders).set("Authorization", "Bearer " + user.accessToken);

  return config;
});

export default instance;