import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import {EventCalendarInitialStateType, EventCalendarResponse} from "../../models/event-calendar-model"
import EventCalendarService from "../../services/event-calendar.service"

interface CalendarByMonthRequest {
    monthId: number,
    year: number
}

const initialState: EventCalendarInitialStateType = {
    data: {} as EventCalendarResponse,
    loading: false,  
    error: undefined,
} ;

export const retrieveEventCalendar = createAsyncThunk('eventCalendar/retrieve',
    async (dateInput: string) => {
        const response = await EventCalendarService.getCalendarEvents(dateInput);
        return response.data
    }
)

export const retrieveEventCalendarByMonth = createAsyncThunk('eventCalendarByMonth/retrieve',
    async (request: CalendarByMonthRequest) => {
        const response = await EventCalendarService.getCalendarEventsByMonth(request.monthId, request.year);
        return response.data
    }
)

const eventCalendarSlice = createSlice({
    name: 'eventCalendar',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveEventCalendar.pending, (state) => {
                    state.loading = true;
            })
            .addCase(retrieveEventCalendar.fulfilled, (state, action) => {
                if(action.payload){
                    state.loading = false;
                    state.data = action.payload as EventCalendarResponse;
                }   
            })        
            .addCase(retrieveEventCalendar.rejected, (state, {payload}) => {
                    state.loading = false
                    state.error = apiErrorHandler(Number(payload))
            })
    }
})

const eventCalendarByMonthSlice = createSlice({
    name: 'eventCalendarByMonth',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveEventCalendarByMonth.pending, (state) => {
                    state.loading = true;
            })
            .addCase(retrieveEventCalendarByMonth.fulfilled, (state, action) => {
                if(action.payload){
                    state.loading = false;
                    state.data = action.payload as EventCalendarResponse;
                }   
            })        
            .addCase(retrieveEventCalendarByMonth.rejected, (state, {payload}) => {
                    state.loading = false
                    state.error = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default {
    eventCalendar: eventCalendarSlice.reducer,
    eventCalendarByMonth: eventCalendarByMonthSlice.reducer
}