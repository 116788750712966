import { useLocation } from "react-router-dom";
import LoadingComp from "../components/re-usable/loading/loading";

const PreviewLayout = () => {
    const location = useLocation();
    const path = location.pathname.split("/-/");
    const wg = location.pathname.split("/wg/")[1].split("/")[0];

    window.location.href = `/${path[1]}?isPreview=true&wg=${wg}`;

  return (
    <>
      <LoadingComp />
    </>
  );
};

export default PreviewLayout;
