import { apiErrorHandler } from './../../errors/api-error-handler';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SliderService from "../../services/slider.service"
import { SliderInitialStateType, SliderType } from '../../models/slider.model';

const initialState: SliderInitialStateType = { 
    SliderData: [],
    SliderPhiladelphiaData: [],
    SliderLoading: true,  
    SliderError: undefined,
} ;  

export const retrieveSliderPhiladelphia = createAsyncThunk('retrieveSliderPhiladelphia/retrieve',
    async (path: string, { rejectWithValue }) => {
        try {
            const response = await SliderService.getAllSlider(path)
         return [response.data]
         
        } catch (error: any) {
            if (!error.response) {
                throw error
              }
            return rejectWithValue(error.response.status)
            
        }
        
    }
)



const SliderPhiladelphia = createSlice({
    name: 'SliderPhiladelphia',
    initialState: initialState,
    reducers: {


    },
    extraReducers: (builder) => {
        builder
            .addCase(retrieveSliderPhiladelphia.pending, (state) => {
                    state.SliderLoading = true;
            })
            .addCase(retrieveSliderPhiladelphia.fulfilled, (state, action) => {
                if(action.payload){
                    state.SliderLoading = false;
                    state.SliderPhiladelphiaData = action.payload as SliderType[];
                }   
            })        
            .addCase(retrieveSliderPhiladelphia.rejected, (state, {payload}) => {
                    state.SliderLoading = false;
                    state.SliderError = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default SliderPhiladelphia.reducer;
