import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import { SearchInitialStateType, SearchResults } from '../../../models/search-page/search-model';
import SearchService from '../../../services/search-page/search.service';

const initialState: SearchInitialStateType = {
    searchData: [],
    loading: true,  
    error: undefined
} ;

export type SearchQueryParamsType = {
    searchText: string,
    taxonomyIds: string,
    // region?: string,
    // professionalInterest?: string,
    pageNumber: number, 
    searchType: string, 
    //contentType: string, 
    relevance: string
}

export const retrieveSearch = createAsyncThunk('search/retrieve',
    async (queryParam: SearchQueryParamsType, { rejectWithValue }) => {
        try {
            
            const response = await SearchService.getSearch(queryParam.searchText, queryParam.taxonomyIds, queryParam.pageNumber, queryParam.searchType, queryParam.relevance)
            return [response.data]
         
        } catch (error: any) {
            if (!error.response) {
                throw error
              }
            return rejectWithValue(error.response.status) 
        }   
    }
)

const searchSlice = createSlice({
    name: 'search',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveSearch.pending, (state) => {
                    state.loading = true;
            })
            .addCase(retrieveSearch.fulfilled, (state, action) => {
                if(action.payload){
                    state.loading = false;
                    state.searchData = action.payload as SearchResults[];
                }   
            })        
            .addCase(retrieveSearch.rejected, (state, {payload}) => {
                    state.loading = false;
                    state.error = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default searchSlice.reducer;
