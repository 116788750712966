import http from "../http-common";

class EventCalendarService {
  getCalendarEvents(dateInput: string) {
    return http.get(`/events/calendar?dateInput=${dateInput}`);
  }

  getCalendarEventsByMonth(monthId: number, year: number) {
    return http.get(`/events/month/${monthId}?year=${year}`)
  }
}

export default new EventCalendarService();
