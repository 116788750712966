import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import {  LatestArticlesResponse, RightSideLatestArticlesInitialStateType } from "../../../models/latest-articles-model";
import { RightSideTrailInitialStateType, RightSideTrailNewsType, RightSideTrailType } from "../../../models/right-side-trail-model";
import ProductRightSideNewsService from '../../../services/right-side/product-right-side-news.service';

const initialState: RightSideTrailInitialStateType = {
    rightSideFeaturedBooksData: [],
    rightSideFeaturedLearningData: [],
    rightSideTrendingNewsData: [],
    rightSidePopularEventsData: [],
    Loading: true,
    Error: undefined,
};

export const retrieveProductRightSideNews = createAsyncThunk('ProductRightSideNews/retrieve',
    async (productPath: string, { rejectWithValue }) => {
        try {

            const response = await ProductRightSideNewsService.getProductRightSideNewsService(productPath)
            return [response.data]

        } catch (error: any) {
            if (!error.response) {
                throw error
            }
            return rejectWithValue(error.response.status)
        }
    }
)

const ProductRightSideNewsSlice = createSlice({
    name: 'ProductRightSideNews',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveProductRightSideNews.pending, (state) => {
                state.Loading = true;
            })
            .addCase(retrieveProductRightSideNews.fulfilled, (state, action) => {
                if (action.payload) {
                    state.Loading = false;
                    state.rightSideTrendingNewsData = action.payload as RightSideTrailNewsType[];
                }
            })
            .addCase(retrieveProductRightSideNews.rejected, (state, { payload }) => {
                state.Loading = false;
                state.Error = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default ProductRightSideNewsSlice.reducer;
