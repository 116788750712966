export const apiErrorHandler = (errorStatus: number) => {
    let errorMessage: string;
    if(errorStatus === 400){
      return errorMessage = 'Bad Request'
    }
    if(errorStatus === 401){
        return errorMessage = 'Unauthorized'
    }
    if(errorStatus === 404){
        return errorMessage = 'Not Found'
    }
    if(errorStatus === 500){
        return errorMessage = 'Internal Server Error'
    } 
}