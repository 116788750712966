import { ReactElement } from "react";

const SiteAlerts = (props: { alertData: string }) => {
  return (
    <>
      {props.alertData ? (
        <div className="row justify-content-center">
          <div className="col-md-12" dangerouslySetInnerHTML={{ __html: props.alertData }}></div>
        </div>
      ) : (
        <>&nbsp;</>
      )}
    </>
  );
};

export default SiteAlerts;
