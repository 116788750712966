import { apiErrorHandler } from './../../errors/api-error-handler';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CookieLawService from "../../services/cookie-law-service"
import { CookieLawInitialStateType, CookieLawTypes } from '../../models/cookie-law-model';

const initialState: CookieLawInitialStateType = {
    CookieLawdata: [],
    CookieLawLoading: true,  
    CookieLawError: undefined,
} ;

export const retrieveCookieLaw = createAsyncThunk('CookieLaw/retrieve',
    async (data, { rejectWithValue }) => {
        try {
            const response = await CookieLawService.getAllCookieLaw();
            return [response.data]
        
        } catch (error: any) {
            if (!error.response) {
                throw error
              }
            return rejectWithValue(error.response.status)
            
        }
        
    }
)


export const postCookieLaw = createAsyncThunk('CookieLaw/post',
    async (cookieLavel: number, { rejectWithValue }) => {
        try {
            const response = await CookieLawService.postCookieLaw(cookieLavel);
            return [response.data]
        
        } catch (error: any) {
            if (!error.response) {
                throw error
              }
            return rejectWithValue(error.response.status)
            
        }
        
    }
)

const cookieLawSlice = createSlice({
    name: 'cookie-law',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(retrieveCookieLaw.pending, (state) => {
                    state.CookieLawLoading = true;
            })
            .addCase(retrieveCookieLaw.fulfilled, (state, action) => {
                if(action.payload){
                    state.CookieLawLoading = false;
                    state.CookieLawdata = action.payload as CookieLawTypes[];
                }   
            })        
            .addCase(retrieveCookieLaw.rejected, (state, {payload}) => {
                    state.CookieLawLoading = false;
                    state.CookieLawError = apiErrorHandler(Number(payload))
            })
            .addCase(postCookieLaw.pending, (state) => {
                state.CookieLawLoading = true;
            })
            .addCase(postCookieLaw.fulfilled, (state, action) => {
                if(action.payload){
                    state.CookieLawLoading = false;
                    state.CookieLawdata = action.payload as CookieLawTypes[];
                }   
            })        
            .addCase(postCookieLaw.rejected, (state, {payload}) => {
                    state.CookieLawLoading = false;
                    state.CookieLawError = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default cookieLawSlice.reducer;
