import http from "../../http-common";

class LoginService {
  login(username: string, password: string) {
    return http.post("/sso/ssoLogin", {
        username: username,
        password: password
    });
  }
}

class LogoutService {
    logout() {
      return http.post("/sso/ssoLogout");
    }
  }

class KenticoLoginService {
  login(username: string, password: string, staySignedIn: boolean) {
    return http.post("/sso/login", {
      username: username,
      password: password,
      staySignedIn: staySignedIn
    })
  }
}

class KenticoLogoutService {
  logout(accessToken: string){
    return http.post("/sso/logout", null, {
      headers: {
        Authorization: "Bearer " + accessToken
      }
    })
  }
}

export default {
    LoginService: new LoginService(),
    LogoutService: new LogoutService(),
    KenticoLoginService: new KenticoLoginService(),
    KenticoLogoutService: new KenticoLogoutService()
}
