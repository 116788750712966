import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import { BreadcrumbInitialStateType, BreadcrumbTypes } from "../../models/breadcrumb-model";
import BreadcrumbServices from "../../services/breadcrumb.service";

const initialState: BreadcrumbInitialStateType = {
    BreadcrumbData: [],
  BreadcrumbLoading: true,
  BreadcrumbError: undefined,
};

export const retrieveBreadcrumb = createAsyncThunk(
  "Breadcrumb/retrieve",
  async (path: string) => {
    const response = await BreadcrumbServices.getAllBreadcrumb(path);
    return [response.data];
  }
);

const BreadcrumbSlice = createSlice({
  name: "Breadcrumb",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveBreadcrumb.pending, (state) => {
        state.BreadcrumbLoading = true;
      })
      .addCase(retrieveBreadcrumb.fulfilled, (state, action) => {
        if (action.payload) {
          state.BreadcrumbLoading = false;
          state.BreadcrumbData = action.payload as BreadcrumbTypes[];
        }
      })
      .addCase(retrieveBreadcrumb.rejected, (state, { payload }) => {
        state.BreadcrumbLoading = false;
        state.BreadcrumbError = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default BreadcrumbSlice.reducer;
