import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { NewsArticlesSearcInitialStateType, NewsArticlesSearcTypes } from "../../../models/news-articles/news-articles-search-model";
import NewsArticlesSearchServices from "../../../services/news-articles/news-articles-search.service";

const initialState: NewsArticlesSearcInitialStateType = {
    NewsArticlesSearcData: [],
    NewsArticlesSearcLoading: true,
    NewsArticlesSearcError: undefined,
};

export type pageSizeType = 12 | 24 | 48 | 96;
export type sortByType = 'Date' | 'Name'
export type NewsArticlesSearchType = {
    keyword: string,
    fromDate: string, 
    toDate: string,
    categoryID: number, 
    authorCode: string,
    pageSize: pageSizeType, 
    sortBy: sortByType, 
    pageNumber: number,
    documentTags: string
}

export const retrieveNewsArticlesSearch = createAsyncThunk(
  "NewsArticlesSearch/retrieve",
  async (queryParam: NewsArticlesSearchType,  { rejectWithValue }) => {
    try {
        const response = await NewsArticlesSearchServices.getAllNewsArticles(queryParam.keyword, queryParam.fromDate, queryParam.toDate, queryParam.categoryID, queryParam.authorCode, queryParam.pageSize, queryParam.sortBy, queryParam.pageNumber, queryParam.documentTags);
        return [response.data];
    }catch (error: any) {
        if (!error.response) {
            throw error
          }
        return rejectWithValue(error.response.status) 
    }   
})
  

const newsArticlesSearchSlice = createSlice({
  name: "NewsArticlesSearc",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveNewsArticlesSearch.pending, (state) => {
        state.NewsArticlesSearcLoading = true;
      })
      .addCase(retrieveNewsArticlesSearch.fulfilled, (state, action) => {
        if (action.payload) {
          state.NewsArticlesSearcLoading = false;
          state.NewsArticlesSearcData = action.payload as NewsArticlesSearcTypes[];
        }
      })
      .addCase(retrieveNewsArticlesSearch.rejected, (state, { payload }) => {
        state.NewsArticlesSearcLoading = false;
        state.NewsArticlesSearcError = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default newsArticlesSearchSlice.reducer;
