import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import {
  RegulatoryFocusInitialStateType,
  RegulatoryFocusType,
} from "../../models/regulatory-focus-model";
import RegulatoryFocusService from "../../services/regulatory-focus.service";

const initialState: RegulatoryFocusInitialStateType = {
  RegulatoryFocusData: [],
  RegulatoryFocusLoading: true,
  RegulatoryFocusError: undefined,
};

export const retrieveRegulatoryFocus = createAsyncThunk(
  "RegulatoryFocus/retrieve",
  async (path: string) => {
    const response = await RegulatoryFocusService.getAllRegulatoryFocus(path);
    return [response.data];
  }
);

const regulatoryFocusSlice = createSlice({
  name: "RegulatoryFocus",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveRegulatoryFocus.pending, (state) => {
        state.RegulatoryFocusLoading = true;
      })
      .addCase(retrieveRegulatoryFocus.fulfilled, (state, action) => {
        if (action.payload) {
          state.RegulatoryFocusLoading = false;
          state.RegulatoryFocusData = action.payload as RegulatoryFocusType[];
        }
      })
      .addCase(retrieveRegulatoryFocus.rejected, (state, { payload }) => {
        state.RegulatoryFocusLoading = false;
        state.RegulatoryFocusError = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default regulatoryFocusSlice.reducer;
