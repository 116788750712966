import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import { AllCoursesInitialStateType, AllCoursesType } from '../../../models/education/all-courses-model';
import AllCoursesService from '../../../services/education/all-courses.service';

const initialState: AllCoursesInitialStateType = {
    AllCoursesData: [],
    AllCoursesLoading: true,
    AllCoursesError: undefined
};

export const retrieveAllCourses = createAsyncThunk('AllCourses/retrieve',
    async (__data , { rejectWithValue }) => {
        try {

            const response = await AllCoursesService.getAllCourses()
            return [response.data]

        } catch (error: any) {
            if (!error.response) {
                throw error
            }
            return rejectWithValue(error.response.status)
        }
    }
)

const AllCoursesSlice = createSlice({
    name: 'AllCourses',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveAllCourses.pending, (state) => {
                state.AllCoursesLoading = true;
            })
            .addCase(retrieveAllCourses.fulfilled, (state, action) => {
                if (action.payload) {
                    state.AllCoursesLoading = false;
                    state.AllCoursesData = action.payload as AllCoursesType[];
                }
            })
            .addCase(retrieveAllCourses.rejected, (state, { payload }) => {
                state.AllCoursesLoading = false;
                state.AllCoursesError = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default AllCoursesSlice.reducer;
