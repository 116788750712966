import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import { CelebrityAwardeesInitialStateType, CelebrityAwardeesTypes } from "../../models/celebrity-awardees-model";
import CelebrityAwardeesServices from "../../services/celebrity-awardees.service";

const initialState: CelebrityAwardeesInitialStateType = {
    CelebrityAwardeesData: [],
  CelebrityAwardeesLoading: true,
  CelebrityAwardeesError: undefined,
};

export const retrieveCelebrityAwardees = createAsyncThunk(
  "CelebrityAwardees/retrieve",
  async (__data) => {
    const response = await CelebrityAwardeesServices.getAllCelebrityAwardees();
    return [response.data];
  }
);

const CelebrityAwardeesSlice = createSlice({
  name: "CelebrityAwardees",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveCelebrityAwardees.pending, (state) => {
        state.CelebrityAwardeesLoading = true;
      })
      .addCase(retrieveCelebrityAwardees.fulfilled, (state, action) => {
        if (action.payload) {
          state.CelebrityAwardeesLoading = false;
          state.CelebrityAwardeesData = action.payload as CelebrityAwardeesTypes[];
        }
      })
      .addCase(retrieveCelebrityAwardees.rejected, (state, { payload }) => {
        state.CelebrityAwardeesLoading = false;
        state.CelebrityAwardeesError = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default CelebrityAwardeesSlice.reducer;
