import { useLocalStorage } from "./useLocalStorage";
import { useState } from "react";

import { useAppDispatch } from "../redux/store";
import { setLoggedInTrue, setLoggedInFalse, setUserData } from "../redux/slice/sso/authentication-slice";
import { logoutUser as resetUserInfo } from "../redux/slice/sso/userinfo.slice";

interface User {
  accessToken: string,
  refreshToken: {
    userName: string,
    tokenString: string,
    expireAt: string
  },
  ssoToken: string;
  userId: string;
  membershipType: string;
};

const useAuth = () => {
  const [user, setUser] = useLocalStorage("user", null);
  const [currentUser, setCurrentUser] = useState(user);
  const dispatch = useAppDispatch();

  // This only works on first refresh
  const getUser = (): User => {
    return currentUser; // user
  };

  const loginUser = (user: User) => {
    dispatch(setUserData(user));
    setCurrentUser(user);
    setUser(user);
    dispatch(setLoggedInTrue());
  }

  const logoutUser = () => {
    dispatch(setUserData(null));
    setCurrentUser(null);
    setUser(null);
    dispatch(setLoggedInFalse());
    dispatch(resetUserInfo());
  }

  return { user, getUser, loginUser, logoutUser }
};

export default useAuth;
