import { apiErrorHandler } from './../../errors/api-error-handler';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SocialMediaContentService from "../../services/social-media-content-service"
import { SocialMediaContentInitialStateType, socialMediaContentType } from '../../models/social-media-content-model';

const initialState: SocialMediaContentInitialStateType = {
    socialMediaContentData: [],
    socialMediaContentLoading: true,  
    socialMediaContentError: undefined,
} ;

export const retrieveSocialMediaContent = createAsyncThunk('SocialMediaContent/retrieve',
    async (data, { rejectWithValue }) => {
        try {
            const response = await SocialMediaContentService.getocialMediaContent();
         return [response.data]
         
        } catch (error: any) {
            if (!error.response) {
                throw error
              }
            return rejectWithValue(error.response.status)
            
        }
        
    }
)



const socialMediaContent = createSlice({
    name: 'SocialMediaContent',
    initialState: initialState,
    reducers: {


    },
    extraReducers: (builder) => {
        builder
            .addCase(retrieveSocialMediaContent.pending, (state) => {
                    state.socialMediaContentLoading = true;
            })
            .addCase(retrieveSocialMediaContent.fulfilled, (state, action) => {
                if(action.payload){
                    state.socialMediaContentLoading = false;
                    state.socialMediaContentData = action.payload as socialMediaContentType[];
                }   
            })        
            .addCase(retrieveSocialMediaContent.rejected, (state, {payload}) => {
                    state.socialMediaContentLoading = false;
                    state.socialMediaContentError = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default socialMediaContent.reducer;
