import { SearchQueryParamsType } from './../search-page/search-slice';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { EventsSearchInitialStateType, EventsSearchType } from "../../../models/events/events-search-model";
import EventSearchServices from "../../../services/events/events-search.service";

const initialState: EventsSearchInitialStateType = {
    EventsSearchData: [],
    EventsSearchLoading: false,
    EventsSearchError: undefined,
};

export type pageSizeType = 12 | 24 | 48;
export type sortByType = 1 | 2 | 3

export type EventSearchType = {
  showOnlyPast: boolean,
  eventType: string, 
  locationInput: string, 
  eventName: string, 
  sortBy: number, 
  pageSize: number, 
  pageNumber: number,
  fromDate: string,
  toDate: string
 
}

export const retrieveEventSearch = createAsyncThunk(
  "EventSearch/retrieve",
  async (queryParam: EventSearchType,   { rejectWithValue }) => {
    try {
        const response = await EventSearchServices.getEventsSearch(
          queryParam.showOnlyPast,
          queryParam.eventType, 
          queryParam.locationInput, 
          queryParam.eventName, 
          queryParam.sortBy, 
          queryParam.pageSize, 
          queryParam.pageNumber,
          queryParam.fromDate,
          queryParam.toDate,
          );
        return [response.data];
    }catch (error: any) {
        if (!error.response) {
            throw error
          }
        return rejectWithValue(error.response.status) 
    }   
})
  

const EventSearchSlice = createSlice({
  name: "EventSearch",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveEventSearch.pending, (state) => {
        state.EventsSearchLoading = true;
      })
      .addCase(retrieveEventSearch.fulfilled, (state, action) => {
        if (action.payload) {
          state.EventsSearchLoading = false;
          state.EventsSearchData = action.payload as EventsSearchType[];
        }
      })
      .addCase(retrieveEventSearch.rejected, (state, { payload }) => {
        state.EventsSearchLoading = false;
        state.EventsSearchError = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default EventSearchSlice.reducer;
