import http from "../http-common";

class UrlRedirectService {
  getRedirectUrl(urlPath: string) {
    let url = `/url-redirect?oldUrl=${urlPath}`;

    return http.get(url);
  }

  getAllRedirectUrls() {
    let url = `/url-redirect/all-redirect-urls`;

    return http.get(url);
  }
}

export default UrlRedirectService;
