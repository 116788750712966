import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../../errors/api-error-handler";
import { LatestFeaturesInitialStateType, LatestFeaturesTypes } from "../../../../models/news-articles/regulatory-focus/latest-features-model";
import LatestFeaturesServices from "../../../../services/news-articles/regulatory-focus/latest-features.service";

const initialState: LatestFeaturesInitialStateType = {
    LatestFeaturesData: [],
    LatestFeaturesLoading: true,
    LatestFeaturesError: undefined,
};



export const retrieveLatestFeatures = createAsyncThunk(
  "LatestFeatures/retrieve",
  async (__data,  { rejectWithValue }) => {
    try {
        const response = await LatestFeaturesServices.getAllRegulatoryFocusLatestFeatures();
        return [response.data];
    }catch (error: any) {
        if (!error.response) {
            throw error
          }
        return rejectWithValue(error.response.status) 
    }   
})


const LatestFeaturesSlice = createSlice({
  name: "NewsArticlesSearc",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveLatestFeatures.pending, (state) => {
        state.LatestFeaturesLoading = true;
      })
      .addCase(retrieveLatestFeatures.fulfilled, (state, action) => {
        if (action.payload) {
          state.LatestFeaturesLoading = false;
          state.LatestFeaturesData = action.payload as LatestFeaturesTypes[];
        }
      })
      .addCase(retrieveLatestFeatures.rejected, (state, { payload }) => {
        state.LatestFeaturesLoading = false;
        state.LatestFeaturesError = apiErrorHandler(Number(payload));
      })
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default LatestFeaturesSlice.reducer;
