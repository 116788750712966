import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { ImpexiumUserCreateInitialStateType, ImpexiumUserCreateResponse, ImpexiumUserCreateType } from "../../../models/impexium/impexium-user-create.model";
import ImpexiumUserCreateService from "../../../services/impexium/impexium-user-create.service";

const initialState: ImpexiumUserCreateInitialStateType = {
  data: {} as ImpexiumUserCreateResponse,
  loading: false,
  error: undefined,
};

export const postImpexiumUserCreate = createAsyncThunk("impexiumUserCreate/post", async (request: ImpexiumUserCreateType) => {
  const service = new ImpexiumUserCreateService();

  const response = await service.postUserCreate(request);
  return response.data;
});

export const cleanImpexiumUserCreate = createAsyncThunk("impexiumUserCreate/clean", async () => {
  return;
});

const impexiumUserCreateSlice = createSlice({
  name: "impexiumUserCreate",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postImpexiumUserCreate.pending, (state) => {
        state.loading = true;
      })
      .addCase(postImpexiumUserCreate.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as ImpexiumUserCreateResponse;
        }
      })
      .addCase(postImpexiumUserCreate.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      }).addCase(cleanImpexiumUserCreate.fulfilled, (state) => {
        state.data = {} as ImpexiumUserCreateResponse;
        state.loading = false;
        state.error = undefined;
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default impexiumUserCreateSlice.reducer;
