import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../errors/api-error-handler";
import { LandingBannersInitialStateType, LandingBannersResponse } from "../../models/landing-banners.model";
import LandingBannersService from "../../services/landing-banners.service";

const initialResponse: LandingBannersResponse = {
  statusCode: 0,
  statusMessage: "",
  data: [],
};

const initialState: LandingBannersInitialStateType = {
  data: initialResponse,
  loading: true,
  error: undefined,
};

export const retrieveLandingBanners = createAsyncThunk("landingBanners/retrieve", async (path: string) => {
  const service = new LandingBannersService();

  const response = await service.getLandingBanners(path);
  return response.data;
});

const landingBannersSlice = createSlice({
  name: "landingBanners",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveLandingBanners.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveLandingBanners.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as LandingBannersResponse;
        }
      })
      .addCase(retrieveLandingBanners.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default landingBannersSlice.reducer;
