import '../../../assets/styles/loader.css'

const LoadingComp = () => {
    return <div className='loader-container'>
        <span className="loader"></span>
    </div>
}

export const LoadingCompSmall = (props: { customStyle?: React.CSSProperties}) => {
    const { customStyle } = props;
    return <span style={customStyle} className="loader"></span>
}

export default LoadingComp;